import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4ba816fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "author" }
const _hoisted_2 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_display = _resolveComponent("date-display")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.author || "Unknown"), 1),
    _createVNode(_component_date_display, { date: _ctx.date }, null, 8, ["date"])
  ]))
}