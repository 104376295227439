import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "panel-object-items-top"
}
const _hoisted_2 = { class: "panel-object-title" }
const _hoisted_3 = { class: "panel-object-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.schema.title || _ctx.schema.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.schema.title), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.schema.description), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schema.properties, (item, key, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: _ctx.panelItemKey + '-' + index + '-' + key + '-' + _ctx.trackingKey,
        class: "panel-object-item"
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent('control-panel-' + item.type), {
          schema: item,
          "panel-item-key": key,
          value: _ctx.getAttributeValue(key, item.type),
          onUpdate: ($event: any) => (_ctx.updateConfig(key, $event))
        }, null, 40, ["schema", "panel-item-key", "value", "onUpdate"]))
      ]))
    }), 128))
  ], 64))
}