export class ListPageModel<T> {
  public pagesToShow = 0;
  public currentPage = 0;
  public itemsPerPage = 0;
  public totalItems = 0;
  public totalPages = 0;
  public hasNextPage = false;
  public hasPreviousPage = false;
  public showingStart = 0;
  public showingEnd = 0;
  public startPage = 0;
  public endPage = 0;
  public page: T[] = [];

  public constructor(model: Partial<ListPageModel<T>>) {
    Object.assign(this, model);
  }
}
