import { BaseModel } from "./BaseModel";
import { getSchemaValues } from "@/components/controlPanel/ControlPanelHelper";

export class Config extends BaseModel {
  public description = "";
  public dataType: ConfigDataType = ConfigDataType.None;
  public folderId = 0;
  public data = "{}";
  public version = 1;
  public source = "cms";
  public staticLookupEntries = "[]";
  public validateJson = true;
  public isNewVersionRequest = false;
  public hash = "";
  public staticLookupHash = "";

  public constructor(model: Partial<Config>) {
    super(model);
    Object.assign(this, model);
    if (this.staticLookupEntries) {
      let tempValue = "";
      try {
        tempValue = JSON.stringify(JSON.parse(this.staticLookupEntries), null, 2);
      } catch {
        tempValue = this.staticLookupEntries;
      }
      this.staticLookupEntries = tempValue;
    }
  }

  public getDataForControlPanel(): JSON {
    let ret = JSON.parse("{}");
    try {
      const dataObject = JSON.parse(this.getDataWithoutJsonComments());
      ret = getSchemaValues(dataObject, /(#CP-)([^:]*?):(.*?)#/);
    } catch (err) {
      console.log("Could not parse the content config to extract the Control Panel declarations.");
    }
    // console.log('Data for Control Panel: ', ret);
    // This returns an object with all of the Control Panel declarations, flattened. {"CP-abc":{}, "CP-efg":{}}
    return ret;
  }

  public getDataWithoutJsonComments(): string {
    return this.data.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m, g) => (g ? "" : m));
  }
}
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ConfigDataType {
  public static None = 0;
  public static Json = 1;
  public static WrappedString = 2;
}
