
import { Options, Vue } from "vue-class-component";
import { verifyUser } from "@/services/UserService";
import { DataResStatus } from "@/models/DataRes";
import { toastAdd } from "@/components/toasts/toastHelper";
import { ToastType } from "@/models/Toast";

@Options({
  components: {},
})
export default class VerifyComponent extends Vue {
  public async mounted(): Promise<void> {
    const verify = await verifyUser(this.$route.params.code as string);
    if (verify.status == DataResStatus.Ok) {
      toastAdd("You have successfully verified your email address.", "Email Verified");
    } else {
      toastAdd("Your verification code appears to be incorrect.", "Oops!", ToastType.Error);
    }
    this.$router.push({ name: "login" });
  }
}
