import { BaseModel } from "./BaseModel";

export class ControlPanel extends BaseModel {
  public data = "";
  public version = 0;
  public configContentId = "";
  public schema = "";

  public constructor(model: Partial<ControlPanel>) {
    super(model);
    Object.assign(this, model);
  }
}
