
import { Options, Vue } from "vue-class-component";
import { JoystickControlPanelSchema } from "./JoystickControlPanelSchema";
import { randomUid } from "@/helpers/random-uid";

@Options({
  props: {
    schema: JSON,
    value: String,
    panelItemKey: String,
  },
  name: "control-panel-string",
  emits: ["update"],
})
export default class ControlPanelString extends Vue {
  public schema!: JoystickControlPanelSchema;
  public value!: string;
  public panelItemKey!: string;

  public valueInComponent = "";
  public randomRadioGroupName = "";
  public inputDisabled = false;
  public inputTypeToShow = "text";
  public placeholder = "";

  public onChange(): void {
    this.$emit("update", this.valueInComponent);
  }

  created(): void {
    this.valueInComponent = this.value ? this.value : "";
    this.inputDisabled = this.schema?.cp_widget_options?.disabled ? true : false;

    if (this.schema?.cp_widget_options?.input_type) {
      if (this.schema.cp_widget_options.input_type == "textarea") {
        this.inputTypeToShow = "textarea";
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.schema.cp_widget_options.input_type == "radio" && this.schema?.enum!.length > 0) {
        this.inputTypeToShow = "radio";
        this.randomRadioGroupName = randomUid(); // Generate a random radio group. This is for when we have arrays.
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.schema.cp_widget_options.input_type == "select" && this.schema?.enum!.length > 0) {
        this.inputTypeToShow = "select";
      }
    }

    this.placeholder = this.schema?.cp_widget_options?.placeholder ? this.schema?.cp_widget_options?.placeholder : "";
  }
}
