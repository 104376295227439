import { User } from "@/models/User";
import { UserLoginResponse } from "@/models/users/UserLoginResponse";
import { UserToken } from "@/models/users/UserToken";
import { request, RequestOptions } from "./BaseService";
import { store } from "@/store";
import { IUserUpdateRequest } from "@/models/users/IUserUpdateRequest";
import { IUserUpdatePasswordRequest } from "@/models/users/IUserUpdatePasswordRequest";
import { DataRes, DataResStatus } from "@/models/DataRes";
import { RegisterRequest } from "@/models/RegisterRequest";
import { event as gtagEvent } from "vue-gtag";
import type { LocationQueryValue, Router } from "vue-router";

const endpoint = "/user";

export const getUser = async (options: RequestOptions = {}): Promise<DataRes<User>> => {
  const user = await request<User>(
    `${endpoint}`,
    {
      method: "GET",
    },
    options
  );

  user.data = new User(user.data);
  return user;
};

export const registerUser = async (data: RegisterRequest): Promise<DataRes<User>> => {
  const registered = await request<User>(`${endpoint}/register`, {
    method: "POST",
    body: JSON.stringify(data),
  });

  registered.data = new User(registered.data);
  return registered;
};

export const loginUserAndRedirect = async (
  email: string,
  password: string,
  router: Router
): Promise<DataRes<UserLoginResponse>> => {
  const registered = await request<UserLoginResponse>(`${endpoint}/login`, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
    }),
  });

  registered.data = new UserLoginResponse(registered.data);

  if (registered.status === DataResStatus.Ok) {
    store.commit("userUpdate", registered.data.user);
    store.commit("tokenUpdate", registered.data.token);
    store.dispatch("tokenAutoRefresh");
    gtagEvent("app_user_login", { event_category: "app_user" });
    router.push((router.currentRoute.value.query.continue as LocationQueryValue) || "/products");
  }
  return registered;
};

export const resetUser = async (email: string): Promise<DataRes<boolean>> => {
  return request<boolean>(`${endpoint}/reset`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
  });
};

export const verifyUser = async (code: string): Promise<DataRes<boolean>> => {
  return request<boolean>(`${endpoint}/verify`, {
    method: "POST",
    body: JSON.stringify({
      code,
    }),
  });
};

export const reverifyUser = async (): Promise<DataRes<boolean>> => {
  return request<boolean>(`${endpoint}/reverify`, {
    method: "POST",
  });
};

export const resetUserConfirm = async (
  code: string,
  password: string,
  passwordConfirmation: string
): Promise<DataRes<boolean>> => {
  return request<boolean>(`${endpoint}/reset/confirm`, {
    method: "POST",
    body: JSON.stringify({
      code,
      password,
      passwordConfirmation,
    }),
  });
};

export const refreshTokenUser = async (refreshToken: string): Promise<DataRes<UserToken>> => {
  const token = await request<UserToken>(`${endpoint}/refreshtoken`, {
    method: "POST",
    body: JSON.stringify({
      refreshToken,
    }),
  });

  token.data = new UserToken(token.data);
  return token;
};

export const attemptTokenFromStorage = async (): Promise<void> => {
  const token = localStorage.getItem("authToken");
  if (token == null) {
    return;
  }
  const tokenParsed: UserToken = JSON.parse(token);
  store.commit("tokenUpdate", tokenParsed);
  store.dispatch("tokenRefresh");
};

export const updateUser = async (update: IUserUpdateRequest): Promise<DataRes<User>> => {
  const user = await request<User>(`${endpoint}/update`, {
    method: "POST",
    body: JSON.stringify(update),
  });

  user.data = new User(user.data);
  return user;
};

export const updateUserPassword = async (update: IUserUpdatePasswordRequest): Promise<DataRes<boolean>> => {
  const user = await request<boolean>(`${endpoint}/password`, {
    method: "POST",
    body: JSON.stringify(update),
  });
  return user;
};
