
import { Options, Vue } from "vue-class-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGifts } from "@fortawesome/free-solid-svg-icons";
import { getDynamicConfig } from "@/services/ConfigService";
import { toastAdd } from "@/components/toasts/toastHelper";
import { ToastType } from "@/models/Toast";
import Copy from "@/components/Copy.vue";
import { DataResStatus } from "@/models/DataRes";

library.add(faGifts);

@Options({
  components: {
    Copy,
  },
})
export default class DemoComponent extends Vue {
  public hasLoaded = false;
  public hasError = false;
  public apiKey = "";
  public contentId = "";
  public userId = "";
  public params = JSON.stringify({ level: 1, days_since_install: 12, location: "hk" }, null, 2);
  public config: unknown = {
    SpecialPricingOffer: {
      OfferTitle: "Special Offer",
      PercentBonus: 30,
      Price: 8.99,
      BackgroundColor: "LightGreen",
      ButtonColor: "LightSalmon",
      ButtonActionText: "Buy Now",
    },
  };
  public fullResponse: unknown = {};
  public sampleContent = `{
  "#CP-Offer:SpecialPricingOffer#": {
    "OfferTitle": "#LK-OfferTitle:string:Special Offer#",
    "PercentBonus": "#LK-Bonus:number:10#",
    "Price": "#LK-Price:number:4.99#",
    "BackgroundColor": "#LK-BackgroundColor:string:LightGreen#",
    "ButtonColor": "#LK-ButtonColor:string:LightSalmon#",
    "ButtonActionText": "#LK-ButtonActionText:string:Buy Now#"
  }
}`;

  public async load(): Promise<void> {
    try {
      this.hasLoaded = false;
      this.hasError = false;
      const res = await getDynamicConfig(JSON.parse(this.params), this.contentId, this.userId, this.apiKey);
      // eslint-disable-next-line no-console
      console.log(res);
      if (res.status == DataResStatus.Ok) {
        this.config = res.data.data;
        this.fullResponse = res.data;
        this.hasLoaded = true;
        toastAdd("Your dynamic content was loaded", "Success");
      } else {
        this.hasError = true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toastAdd("Failed to load dynamic content", "Oops", ToastType.Error);
      this.hasLoaded = true;
    }
  }
}
