
import { Options, Vue } from "vue-class-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGifts, faGamepad, faIcons } from "@fortawesome/free-solid-svg-icons";
import { getDynamicConfig } from "@/services/ConfigService";
import { toastAdd } from "@/components/toasts/toastHelper";
import { ToastType } from "@/models/Toast";
import Copy from "@/components/Copy.vue";
import { DataResStatus } from "@/models/DataRes";

library.add(faGifts, faGamepad, faIcons);

type MyFunction = () => Promise<boolean>;

@Options({
  components: {
    Copy,
  },
})
export default class DemoComponent extends Vue {
  public hasLoaded = false;
  public showFullResponse = false; // Show the full response sent by Joystick
  public showEnvB = false;
  public hasError = false;
  public pollForConfigs = false;
  public apiKeyEnvA = "";
  public apiKeyEnvB = "";
  public contentId = "simple_offer";
  public liveIndicator = "-&nbsp;";
  public userId = "abc123";
  public params = JSON.stringify(
    { points: 1, days_since_install: 12, location: "earth", tags: ["big", "bright", "blue"] },
    null,
    2
  );
  public fullResponseEnvA: any = {};
  public fullResponseEnvB: any = {};
  public sampleContentClean = `{
  "SpecialPricingOffer": {
    "OfferTitle": "Special Offer",
    "PercentBonus": "10",
    "Price": "4.99",
    "BackgroundColor": "LightGreen",
    "ButtonColor": "LightSalmon",
    "ButtonActionText": "Buy Now",
    "Item": "gifts" // gifts, gamepad, icons
  }}`;

  public sampleContentDynamic = `{
    "SpecialPricingOffer": "#LK-offer:object:offer#",
    "_dynamic_values_": {"offer": {
      "OfferTitle": "Special Offer",
      "PercentBonus": "10",
      "Price": "4.99",
      "BackgroundColor": "LightGreen",
      "ButtonColor": "LightSalmon",
      "ButtonActionText": "Buy Now",
      "Item": "gifts" // gifts, gamepad, icons
  }}}
  `;

  public sampleContentDynamicMap = `
  [{"name": "MyTest", "expression": "location = 'earth' and StringIn('big', tags)", "activationDateTime": {"start": "2022-01-01T01:01:01Z", "end": "2099-12-31T23:34:45Z", "overrideAndActivateNow": false }, "info": "Additional info", "groups": [{"variantName": "Variant A", "mod": [[0, 499 ] ], "values": {"LK-offer": {"OfferTitle": "Amazing Deal", "PercentBonus": "30", "Price": "9.99", "BackgroundColor": "Orange", "ButtonColor": "LightGreen", "ButtonActionText": "Try This", "Item": "icons"} } }, {"variantName": "Variant B", "mod": [[500, 999 ] ], "values": {"LK-offer": {"OfferTitle": "Limited Time", "PercentBonus": "50", "Price": "12.99", "BackgroundColor": "LightBlue", "ButtonColor": "LightSalmon", "ButtonActionText": "Activate Deal", "Item": "gamepad"} } } ] } ]
  `;

  public async poll(fn: MyFunction, delayMs: number): Promise<void> {
    let noError = true;
    noError = await fn();
    while (this.pollForConfigs && noError) {
      await this.wait(delayMs);
      noError = await fn();
      this.liveIndicator = this.liveIndicator == "-&nbsp;" ? "&nbsp;-" : "-&nbsp;";
    }
  }

  public wait(ms = 2000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  public async getData(): Promise<boolean> {
    const resEnvA = await getDynamicConfig(JSON.parse(this.params), this.contentId, this.userId, this.apiKeyEnvA);
    const resEnvB = await getDynamicConfig(JSON.parse(this.params), this.contentId, this.userId, this.apiKeyEnvB);
    console.log("%c=== Response from Joystick Dynamic Config API ===", "color: orange");
    console.log("Environment A: ", resEnvA);
    console.log("Environment B: ", resEnvB);
    if (resEnvA.status == DataResStatus.Ok && resEnvB.status == DataResStatus.Ok) {
      this.fullResponseEnvA = resEnvA.data;
      this.fullResponseEnvB = resEnvB.data;
      this.hasLoaded = true;
      return true;
    } else {
      this.hasError = true;
      this.pollForConfigs = false;
      return false;
    }
  }

  public async load(): Promise<void> {
    try {
      this.hasLoaded = false;
      this.hasError = false;
      this.poll(this.getData, 2000);
    } catch (e) {
      console.error(e);
      toastAdd("Failed to load dynamic content.", "Oops", ToastType.Error);
      this.hasLoaded = true;
    }
  }
}
