import { DataResStatus } from "@/models/DataRes";
import { EnvironmentDto } from "@/models/environments/EnvironmentDto";
import {
  getSyncEnvData,
  SyncEnvContentComparisonResponse,
  SyncEnvContentItem,
  pushSyncEnvData,
  PushContentItem,
} from "@/services/SyncEnvironmentService";
import { createPushRequest } from "@/services/PushRequestServices";
import { FeatureGroupWithConfigs } from "@/models/FeatureGroup";
import { canContentBeSelected } from "@/components/syncEnvironments/helpers";
import map from "lodash/map";
import find from "lodash/find";
import flatten from "lodash/flatten";

export type SyncEnvState = {
  source: EnvironmentDto | null;
  target: EnvironmentDto | null;
  contentComparison: SyncEnvContentComparisonResponse | null;
  isLoadingContentComparison: boolean;
  pushContentInProgress: boolean;
  contentsToPush: Map<string, SyncEnvContentItem>;
  showOnlySelected: boolean;
  hideIdenticalContent: boolean;
  collapsedContentComparisons: Set<number>;
  featureGroupsWithConfigs: FeatureGroupWithConfigs[];
  pushRequestName: string;
  // many to many table for keeping state of feature group and config selection
  featureGroupsConfigsManyToMany: { configContentId: string; groupContentId: string }[];
};

const syncEnvState = (): SyncEnvState => ({
  source: null,
  target: null,
  contentComparison: null,
  isLoadingContentComparison: false,
  pushContentInProgress: false,
  contentsToPush: new Map(),
  showOnlySelected: false,
  hideIdenticalContent: false,
  collapsedContentComparisons: new Set(),
  featureGroupsWithConfigs: [],
  featureGroupsConfigsManyToMany: [],
  pushRequestName: "",
});

const getters = {
  source: (state: SyncEnvState) => state.source,
  target: (state: SyncEnvState) => state.target,
  contentComparison: (state: SyncEnvState) => state.contentComparison,
  contentComparisonItems: (state: SyncEnvState) => state.contentComparison?.contentComparison || [],
  allContents: (state: SyncEnvState): SyncEnvContentItem[] =>
    flatten(map(state.contentComparison?.contentComparison, "contents")),
  isLoadingContentComparison: (state: SyncEnvState) => state.isLoadingContentComparison,
  contentsToPush: (state: SyncEnvState) => state.contentsToPush,
  pushContentInProgress: (state: SyncEnvState) => state.pushContentInProgress,
  showOnlySelected: (state: SyncEnvState) => state.showOnlySelected,
  hideIdenticalContent: (state: SyncEnvState) => state.hideIdenticalContent,
  collapsedContentComparisons: (state: SyncEnvState) => state.collapsedContentComparisons,

  featureGroupsWithConfigs: (state: SyncEnvState) => state.featureGroupsWithConfigs,
  featureGroupsConfigsManyToMany: (state: SyncEnvState) => state.featureGroupsConfigsManyToMany,

  selectedFeatureGroupsContentIds: getSelectedFeatureGroupsContentIds,

  notInTargetContentsCount: (state: SyncEnvState) => {
    let count = 0;

    state.contentsToPush.forEach((content: SyncEnvContentItem) => {
      if (content.syncStatus === "not-in-target") {
        count++;
      }
    });

    return count;
  },
  existingInTargetContentsCount: (state: SyncEnvState, { notInTargetContentsCount }) => {
    return state.contentsToPush.size - notInTargetContentsCount;
  },
};

const actions = {
  selectSource({ commit }, source: SyncEnvState["source"]) {
    commit("setSource", source);
  },
  selectTarget({ commit }, target: SyncEnvState["target"]) {
    commit("setTarget", target);
  },
  selectContentToPush({ commit }, contentItem: SyncEnvContentItem) {
    commit("addContentToPush", contentItem);
  },
  deselectContentToPush({ commit }, contentItem: SyncEnvContentItem) {
    commit("removeContentToPush", contentItem);
  },
  selectNone({ commit }) {
    commit("clearContentsToPush");
  },
  changePushRequestName({ commit }, name: string) {
    commit("setPushRequestName", name);
  },
  async fetchSyncEnvContent({ commit, state, rootState }) {
    const sourceContentId = state.source.contentId;
    const targetContentId = state.target.contentId;
    const productContentId = rootState.product.contentId;
    commit("clearContentsToPush");
    commit("setIsLoadingContentComparison", true);

    const data = await getSyncEnvData({
      sourceContentId,
      targetContentId,
      productContentId,
    });

    if (data.status === DataResStatus.Ok) {
      commit("setContentComparison", data.data);
    } else {
      commit("setContentComparison", null);
    }

    commit("setIsLoadingContentComparison", false);
  },
  async pushContent({ commit, state, rootState }) {
    commit("setPushContentInProgress", true);
    const contents: PushContentItem[] = [];
    const sourceContentId = state.source.contentId;
    const targetContentId = state.target.contentId;
    const productContentId = rootState.product.contentId;

    state.contentsToPush.forEach((content: SyncEnvContentItem) => {
      contents.push({
        contentId: content.contentId,
        toTarget: true,
        folder: content.location.sourceFullPath as string, // Yes, this is correct, the destination folder should be the same as the sourceFullPath.
        version: content.source.version,
      });
    });

    const response = await pushSyncEnvData({
      contents,
      sourceContentId,
      targetContentId,
      productContentId,
    });

    commit("setPushContentInProgress", false);
    return response;
  },
  reset({ commit }) {
    commit("setContentComparison", null);
    commit("setSource", null);
    commit("setTarget", null);
    commit("clearContentsToPush");
  },
  toggleFeatureGroupConfigs({ commit, getters }, group: FeatureGroupWithConfigs) {
    if (!getters.selectedFeatureGroupsContentIds.includes(group.contentId)) {
      commit("selectFeatureGroupConfigs", {
        group,
        allContents: getters.allContents,
      });
    } else {
      commit("deselectFeatureGroupConfigs", {
        group,
        allContents: getters.allContents,
        selectedFeatureGroupsContentIds: getters.selectedFeatureGroupsContentIds,
      });
    }
  },
  async createPushRequest({ commit, state, rootState }) {
    commit("setPushContentInProgress", true);
    const contents: PushContentItem[] = [];
    const sourceContentId = state.source.contentId;
    const targetContentId = state.target.contentId;
    const productContentId = rootState.product.contentId;
    const pushRequestName = state.pushRequestName;

    state.contentsToPush.forEach((content: SyncEnvContentItem) => {
      contents.push({
        contentId: content.contentId,
        toTarget: true,
        folder: content.location.sourceFullPath as string, // Yes, this is correct, the destination folder should be the same as the sourceFullPath.
        version: content.source.version,
      });
    });

    const response = await createPushRequest({
      productContentId,
      contents,
      sourceContentId,
      targetContentId,
      pushRequestName,
    });

    commit("setPushContentInProgress", false);
    return response;
  },
};

const mutations = {
  setSource(state: SyncEnvState, source: EnvironmentDto) {
    state.source = source;
  },
  setTarget(state: SyncEnvState, target: EnvironmentDto) {
    state.target = target;
  },
  setContentComparison(state: SyncEnvState, contentComparison: SyncEnvContentComparisonResponse | null) {
    state.contentComparison = contentComparison;
  },
  setIsLoadingContentComparison(state: SyncEnvState, isLoading: boolean) {
    state.isLoadingContentComparison = isLoading;
  },
  addContentToPush(state: SyncEnvState, contentItem: SyncEnvContentItem) {
    const featureGroups = state.featureGroupsWithConfigs.filter((g) => {
      return g.configs.includes(contentItem.contentId);
    });

    featureGroups.forEach((g) => {
      // safity check
      const exists = find(state.featureGroupsConfigsManyToMany, {
        configContentId: contentItem.contentId,
        groupContentId: g.contentId,
      });

      if (!exists) {
        state.featureGroupsConfigsManyToMany.push({
          configContentId: contentItem.contentId,
          groupContentId: g.contentId,
        });
      }
    });
    state.contentsToPush.set(contentItem.contentId, contentItem);
  },
  removeContentToPush(state: SyncEnvState, contentItem: SyncEnvContentItem) {
    state.featureGroupsConfigsManyToMany = state.featureGroupsConfigsManyToMany.filter(
      (fc) => fc.configContentId !== contentItem.contentId
    );
    state.contentsToPush.delete(contentItem.contentId);
  },
  clearContentsToPush(state: SyncEnvState) {
    state.contentsToPush.clear();
    state.collapsedContentComparisons = new Set();
    state.featureGroupsConfigsManyToMany = [];
  },
  setPushContentInProgress(state: SyncEnvState, value: boolean) {
    state.pushContentInProgress = value;
  },
  toggleShowOnlySelected(state: SyncEnvState) {
    state.showOnlySelected = !state.showOnlySelected;
  },
  toggleHideIdenticalContent(state: SyncEnvState) {
    state.hideIdenticalContent = !state.hideIdenticalContent;
  },
  collapseContentComparison(state: SyncEnvState, key: number) {
    state.collapsedContentComparisons.add(key);
  },
  expandContentComparison(state: SyncEnvState, key: number) {
    state.collapsedContentComparisons.delete(key);
  },
  setFeatureGroupsWithConfigs(state: SyncEnvState, groups: FeatureGroupWithConfigs[]) {
    state.featureGroupsWithConfigs = groups;
  },
  selectFeatureGroupConfigs(
    state: SyncEnvState,
    { group, allContents }: { group: FeatureGroupWithConfigs; allContents: SyncEnvContentItem[] }
  ) {
    const groupContents = allContents.filter((c) => canContentBeSelected(c) && group.configs.includes(c.contentId));
    groupContents.forEach((c) => {
      state.contentsToPush.set(c.contentId, c);
      state.featureGroupsConfigsManyToMany.push({
        configContentId: c.contentId,
        groupContentId: group.contentId,
      });
    });
  },
  deselectFeatureGroupConfigs(
    state: SyncEnvState,
    { group, allContents }: { group: FeatureGroupWithConfigs; allContents: SyncEnvContentItem[] }
  ) {
    const groupContents = allContents.filter((c) => canContentBeSelected(c) && group.configs.includes(c.contentId));

    const selectedFeatureGroups = getSelectedFeatureGroupsContentIds(state).filter((id) => id !== group.contentId);

    state.featureGroupsConfigsManyToMany = state.featureGroupsConfigsManyToMany.filter((fc) => {
      return fc.groupContentId !== group.contentId && selectedFeatureGroups.includes(fc.groupContentId);
    });

    groupContents.forEach((c) => {
      const keepSelected = state.featureGroupsConfigsManyToMany.find((fc) => c.contentId === fc.configContentId);
      if (!keepSelected) {
        state.contentsToPush.delete(c.contentId);
      }
    });
  },
  setPushRequestName(state: SyncEnvState, name: string) {
    state.pushRequestName = name;
  },
};

function getSelectedFeatureGroupsContentIds(state: SyncEnvState) {
  const manyToManyTable = state.featureGroupsConfigsManyToMany;
  const featureGroups = state.featureGroupsWithConfigs;
  const contentIds: string[] = [];
  const allContents = flatten(map(state.contentComparison?.contentComparison, "contents"));
  const selectableContents = allContents.filter((c) => canContentBeSelected(c));

  featureGroups.forEach((group) => {
    const groupContents = selectableContents.filter((c) => group.configs.includes(c.contentId));
    if (!groupContents.length) {
      return;
    }

    const isSelected = groupContents.every((c) =>
      find(manyToManyTable, { configContentId: c.contentId, groupContentId: group.contentId })
    );

    if (isSelected) {
      contentIds.push(group.contentId);
    }
  });

  return contentIds;
}

export default {
  namespaced: true,
  state: syncEnvState,
  getters,
  actions,
  mutations,
};
