import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import { Vue } from "vue-class-component";
import { initLogger } from "./services/LoggerService";
import "./styles-setup";

// 3d party.

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vSelect from "vue-select";

import VueApexCharts from "vue3-apexcharts";

import DateComponent from "@/components/Date.vue";
import AuthorComponent from "@/components/Author.vue";
import AlertComponent from "@/components/Alert.vue";
import SelectorComponent from "@/components/Selector.vue";
import { LoadingDirective } from "./directives/LoadingDirective";
import VueGtag, { PageView } from "vue-gtag";

// For Control Panel. These two are necessary to be global because it would otherwise cause recursion (n-level array in objects)
import ControlPanelArray from "@/components/controlPanel/ControlPanelArray.vue";
import ControlPanelObject from "@/components/controlPanel/ControlPanelObject.vue";

// TODO: Make this a component
import VueHighlightJS from "vue3-highlightjs";
import "highlight.js/styles/monokai.css";
import { RouteLocationNormalized } from "vue-router";
import { VueQueryPlugin } from "@tanstack/vue-query";
// initialize logging service (currently – datadog)
initLogger();

// Register the router hooks with their names
Vue.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

const app = createApp(App);
app.use(store, key);
app.use(router);
app.use(VueQueryPlugin);
app.use(VueHighlightJS); // TODO: Make this a component
app.directive("loading", LoadingDirective);
app.component("DateDisplay", DateComponent);
app.component("AppAuthor", AuthorComponent);
app.component("AppAlert", AlertComponent);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("AppSelector", SelectorComponent);
app.component("ControlPanelArray", ControlPanelArray);
app.component("ControlPanelObject", ControlPanelObject);
app.component("VSelect", vSelect);

const pageTrackerTemplate = (to: RouteLocationNormalized): PageView => {
  return {
    page_title: "app-" + to.name?.toString(),
    page_path: to.path,
    page_location: window.location.href,
  };
};
app.use(
  VueGtag,
  {
    pageTrackerEnabled: true, // auto route tracking
    config: {
      id: process.env.VUE_APP_GTAG_ID ?? "",
      params: {
        send_page_view: false, // Double page_view event issue with vue-gtag and gtag4. The browser history change will also trigger and event.
      },
    },
    // Original types has incorrect definition: https://github.com/MatteoGabriele/vue-gtag/issues/533
    // Documentation states method accepts two params: https://matteo-gabriele.gitbook.io/vue-gtag/auto-tracking#page-tracker-template
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageTrackerTemplate: pageTrackerTemplate as any,
  },
  router
);

app.use(VueApexCharts);

// TODO: value:any is not good. Fix it.
// TODO: Fix: if just using a regular string, it will put quotes around it. Make able to handle both regular string and JSON.
app.config.globalProperties.$filters = {
  str_limit(value: unknown, size: number, processAsString = false): string {
    if (!value) return "";
    const valueString = processAsString ? (value as string) : JSON.stringify(value);

    if (valueString.length <= size) {
      return valueString;
    }
    return valueString.substring(0, size) + "...";
  },
  string_to_color(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substring(-2);
    }
    return color;
  },
};
app.mount("#app");
