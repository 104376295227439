import { ENV_COLOR_MAP, ENV_ICON_MAP, EnvironmentColor, EnvironmentIcon, EnvironmentType } from "./Environment";

export class EnvironmentDto {
  public hid = "";
  public contentId = "";
  public name = "";
  public description = "";
  public type = EnvironmentType.None;
  public color = EnvironmentColor.None;
  public icon = EnvironmentIcon.None;
  public pushProtected = false;
  public pushProtectedApprovalsRequired = 1;
  public organizationHid = "";
  public productHid = "";
  public shadowType = "";
  public isArchived = false;

  public constructor(model: Partial<EnvironmentDto>) {
    Object.assign(this, model);
  }

  public get colorVal() {
    const currentColor = ENV_COLOR_MAP[this.color];
    return currentColor ? `#${currentColor}` : `#${ENV_COLOR_MAP[EnvironmentColor.Default]}`;
  }

  public get iconVal() {
    return this.icon ? ENV_ICON_MAP[this.icon] : "code-s-slash-line";
  }

  infoIsValid() {
    if (this.name.length < 2 || this.name.length > 50 || this.description.length < 2 || this.description.length > 140) {
      return false;
    }

    return true;
  }
}
