import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "panel-item-top" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "panel-item-key" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["name", "value", "disabled"]
const _hoisted_6 = { class: "panel-radio-label-text" }
const _hoisted_7 = ["name", "disabled"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["name", "placeholder", "disabled"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'widget-input-disabled': _ctx.inputDisabled })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "panel-item-label",
        innerHTML: _ctx.schema.title
      }, null, 8, _hoisted_2),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.panelItemKey), 1)
    ]),
    (_ctx.inputTypeToShow == 'radio')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schema.enum, (item, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              class: "panel-radio-option"
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueInComponent) = $event)),
                type: "radio",
                name: _ctx.randomRadioGroupName,
                value: item,
                disabled: _ctx.inputDisabled,
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
              }, null, 40, _hoisted_5), [
                [_vModelRadio, _ctx.valueInComponent]
              ]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(item), 1)
            ]))
          }), 128))
        ]))
      : (_ctx.inputTypeToShow == 'select')
        ? _withDirectives((_openBlock(), _createElementBlock("select", {
            key: 1,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valueInComponent) = $event)),
            name: _ctx.panelItemKey,
            disabled: _ctx.inputDisabled,
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schema.enum, (item, index) => {
              return (_openBlock(), _createElementBlock("option", {
                key: index,
                value: item
              }, _toDisplayString(item), 9, _hoisted_8))
            }), 128))
          ], 40, _hoisted_7)), [
            [
              _vModelSelect,
              _ctx.valueInComponent,
              void 0,
              { number: true }
            ]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valueInComponent) = $event)),
            type: "number",
            name: _ctx.panelItemKey,
            placeholder: _ctx.placeholder,
            pattern: "[0-9]+",
            disabled: _ctx.inputDisabled,
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }, null, 40, _hoisted_9)), [
            [
              _vModelText,
              _ctx.valueInComponent,
              void 0,
              { number: true }
            ]
          ]),
    _createElementVNode("span", {
      class: "help",
      innerHTML: _ctx.schema.description
    }, null, 8, _hoisted_10)
  ], 2))
}