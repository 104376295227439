
import { Options, Vue } from "vue-class-component";
import { getDynamicConfig } from "@/services/ConfigService";
import { toastAdd } from "@/components/toasts/toastHelper";
import { ToastType } from "@/models/Toast";
import Copy from "@/components/Copy.vue";
import { DataResStatus } from "@/models/DataRes";
import { config } from "@/config";

@Options({
  components: {
    Copy,
  },
})
export default class DemoComponent extends Vue {
  public hasLoaded = false;
  public hasError = false;
  public apiKey = "";
  public contentId = "";
  public userId = "";
  public params = JSON.stringify({ level: 1, days_since_install: 12, location: "earth" }, null, 2);
  public fullResponse: any = {};

  public endpoint = config.apiEndpoint;

  beforeMount() {
    if (this.$route.params.contentId) {
      this.contentId = this.$route.params.contentId.toString();
    }
  }

  public async load(): Promise<void> {
    try {
      JSON.parse(this.params);
    } catch (e) {
      toastAdd("User Parameters is not valid JSON. At minimum it should be {}.", "Oops...", ToastType.Error);
      this.hasLoaded = true;
    }

    try {
      this.hasLoaded = false;
      this.hasError = false;
      const res = await getDynamicConfig(JSON.parse(this.params), this.contentId, this.userId, this.apiKey);
      if (res.status == DataResStatus.Ok) {
        this.fullResponse = res.data;
        delete this.fullResponse.meta.variants;
        this.hasLoaded = true;
        toastAdd("Your dynamic content was loaded.", "Success!");
      } else {
        this.hasError = true;
      }
    } catch (e) {
      console.error(e);
      toastAdd("Failed to load dynamic content.", "Oops", ToastType.Error);
      this.hasError = true;
    }
  }
}
