import { User } from "./User";

export class RegisterRequest extends User {
  public cloudflareTurnstileToken = "";
  public password = "";
  public passwordConfirmation = "";

  public constructor(model: Partial<RegisterRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
