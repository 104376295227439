import { toastAdd } from "@/components/toasts/toastHelper";
import { DataRes, DataResStatus } from "@/models/DataRes";
import { ToastType } from "@/models/Toast";
import { store } from "@/store";
import { UserToken } from "@/models/users/UserToken";
import { loggerFor } from "./LoggerService";

export const endpoint = process.env.VUE_APP_API_ENDPOINT; // No trailing slash!
export const REQUEST_END = "REQUEST_END";
export const REQUEST_START = "REQUEST_START";

const logger = loggerFor("BaseService");
export interface RequestOptions {
  /**
   * Whether or not to bubble up the errors
   */
  noPropagate?: boolean;
  customEndpoint?: string;
  headers?: { [key: string]: string };
}

export const request = async <T>(
  url: string,
  settings: RequestInit = {},
  options: RequestOptions = {}
): Promise<DataRes<T>> => {
  window.dispatchEvent(new CustomEvent(REQUEST_START));

  let dataResult = new DataRes<T>({});
  let response: Response = new Response();
  try {
    settings.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (store.getters.isAuthenticated) {
      settings.headers["Authorization"] = `Bearer ${store.state.token.accessToken}`;
    }

    if (options.headers) {
      settings.headers = Object.assign(settings.headers, options.headers);
    }

    let fullUrl = `${endpoint}${url}`;
    if (options.customEndpoint) {
      fullUrl = `${options.customEndpoint}${url}`;
    }

    const req = new Request(fullUrl, settings);
    response = await fetch(req);

    if (!response.ok) {
      // Throw out the existing token since it is useless. Also store.getters.isAuthenticated will be then be correctly false.
      // Don't logout if the API tester or demo is used with the incorrect API Key (returns 401)
      if (response.status === 401 && !fullUrl.includes("/api/v1/config/"))
        store.commit("tokenUpdate", new UserToken({}));
      throw new Error("Intermediate error");
    }
    const res = await response.json();
    dataResult = new DataRes<T>({
      status: DataResStatus.Ok,
      data: res,
    });
  } catch (e) {
    dataResult = await parseRequestError<T>(response);
    if (!options.noPropagate && Array.isArray(dataResult.messages) && dataResult.messages.length > 0) {
      for (const e of dataResult.messages) {
        const eMsg = e ? e : ""; // there is a case where messages is array of one null [null].
        toastAdd(eMsg, "Oops, something went wrong.", ToastType.Error);
      }
    }
  }

  window.dispatchEvent(new CustomEvent(REQUEST_END));
  return dataResult;
};

export const requestBlob = async (
  url: string,
  settings: RequestInit = {},
  options: RequestOptions = {}
): Promise<Blob> => {
  window.dispatchEvent(new CustomEvent(REQUEST_START));

  let dataResult = new DataRes({});
  let response: Response = new Response();
  try {
    settings.headers = {
      "Content-Type": "application/octet-stream",
      Accept: "application/octet-stream",
    };

    if (store.getters.isAuthenticated) {
      settings.headers["Authorization"] = `Bearer ${store.state.token.accessToken}`;
    }

    if (options.headers) {
      settings.headers = Object.assign(settings.headers, options.headers);
    }

    let fullUrl = `${endpoint}${url}`;
    if (options.customEndpoint) {
      fullUrl = `${options.customEndpoint}${url}`;
    }

    const req = new Request(fullUrl, settings);
    response = await fetch(req);
    if (!response.ok) {
      throw new Error("Intermediate error");
    }
    window.dispatchEvent(new CustomEvent(REQUEST_END));
    return await response.blob();
  } catch (e) {
    if (!options.noPropagate) {
      dataResult = await parseRequestError(response);
      for (const e of dataResult.messages) {
        const eMsg = e ? e : ""; // there is a case where messages is array of one null [null].
        toastAdd(eMsg, "Oops, something went wrong.", ToastType.Error);
      }
    }
  }

  window.dispatchEvent(new CustomEvent(REQUEST_END));
  return new Blob();
};

export const parseRequestError = async <T>(response: Response): Promise<DataRes<T>> => {
  const res = new DataRes<T>({
    status: DataResStatus.Error,
  });

  let json;
  try {
    json = await response.json();
    if (Object.prototype.hasOwnProperty.call(json, "errors")) {
      const keys = Object.keys(json.errors);
      for (const key of keys) {
        res.messages = [...json.errors[key].map((x: string) => `${key}: ${x}`), ...res.messages];
      }
    } else if (Object.prototype.hasOwnProperty.call(json, "message")) {
      res.messages = [json.message];
    } else {
      res.messages = ["Something went wrong with the request."];
    }
  } catch (e) {
    // json probably didnt work well.
    res.messages = [response.statusText];
  }

  if (response.status >= 400 && response.status < 500) {
    logger.error(`Server returned ${response.status} ${response.statusText}`, {
      status: response.status,
      statusText: response.statusText,
      redactedPayload: {
        status: res?.status ?? "Unknown",
        messages: res?.messages ?? "Unknown",
      },
    });
  }
  return res;
};
