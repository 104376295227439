
import { Options, Vue } from "vue-class-component";
import { UserInvitation } from "@/models/User";
import { RegisterRequest } from "@/models/RegisterRequest";
import { loginUserAndRedirect, registerUser } from "@/services/UserService";
import { toastAdd } from "@/components/toasts/toastHelper";
import InvitationCard from "@/components/InvitationCard.vue";
import { DataResStatus } from "@/models/DataRes";
import { LoadingStatus } from "@/directives/LoadingDirective";
import { event as gtagEvent } from "vue-gtag";
import { config } from "@/config";
import VueTurnstile from "@getjoystick/vue-turnstile";
import { loggerFor } from "@/services/LoggerService";

const logger = loggerFor("OnboardingComponent");

@Options({
  components: {
    InvitationCard,
    VueTurnstile,
  },
})
export default class OnboardingComponent extends Vue {
  public registerRequest: RegisterRequest = new RegisterRequest({ invitationCode: "public-joystick" });
  public isActive = true;
  public status: LoadingStatus = LoadingStatus.Disabled;
  public showInputInvitationCode = false; // Anyone can register.
  public displayInvitations = false;
  public userInvitations: UserInvitation[] = [];
  public disableInvitationCodeInput = true;
  public cfTurnstileSiteKey = config.cloudflareTurnstileSiteKey;
  public cfTurnstileToken = "";
  public signupOriginatesFromInvitation = false;
  public agreedToTerms = false;

  public declare $refs: {
    cfTurnstile: typeof VueTurnstile;
  };

  set username(username: string) {
    this.registerRequest.username = username.toLowerCase();
  }

  get username() {
    return this.registerRequest.username;
  }

  public async register(): Promise<void> {
    this.status = LoadingStatus.Loading;
    logger.info("Registering user", { registerRequest: { username: this.username } });
    const user = await registerUser(this.registerRequest);

    if (user.status === DataResStatus.Ok) {
      logger.info("User is registered", {
        user: { hid: user?.data?.hid },
        invitationsNumber: user.data.invitations?.length,
      });
      gtagEvent("app_user_create", { event_category: "app_user" });
      toastAdd("Your account has been created!", "Account Created Successfully");
      this.status = LoadingStatus.Success;
      if (user.data.invitations?.length) {
        this.userInvitations = user.data.invitations;
        this.displayInvitations = true;
      } else {
        logger.info("Logging in a user automatically");
        await loginUserAndRedirect(this.registerRequest.email, this.registerRequest.password, this.$router);
      }
    } else {
      logger.error("User registration is failed", { response: user });
      this.status = LoadingStatus.None;
      this.$refs.cfTurnstile.reset();
    }
  }

  public onRegisterButtonClick() {
    logger.info("Register button clicked", { buttonStatus: LoadingStatus[this.status] });
  }

  public mounted(): void {
    logger.info("Onboarding page is opened", { route: this.$route?.fullPath });
    this.$watch(
      "cfTurnstileToken",
      (token: string) => {
        logger.info("Turnstile token is changed", { tokenIsEmpty: token === "" });

        if (token && token != "") {
          this.registerRequest.cloudflareTurnstileToken = token;
        } else {
          this.registerRequest.cloudflareTurnstileToken = "";
        }
      },
      { immediate: true }
    );

    // Enable or disable the register button based on if the user has agreed to the terms and CF token is available.
    const validateFormAndChangeStatus = () => {
      if (this.agreedToTerms && this.registerRequest?.cloudflareTurnstileToken != "") {
        this.status = LoadingStatus.None;
      } else {
        this.status = LoadingStatus.Disabled;
      }
    };
    this.$watch("agreedToTerms", validateFormAndChangeStatus, { immediate: true });

    this.$watch("registerRequest.cloudflareTurnstileToken", validateFormAndChangeStatus, { immediate: true });

    if (typeof this.$route.query.invitation_code === "undefined" || this.$route.query.invitation_code == null) return;

    // Invitation code passed in from email link.
    if (this.$route.query.invitation_code.length == 64) {
      this.registerRequest.invitationCode = this.$route.query.invitation_code.toString();
      this.signupOriginatesFromInvitation = true;
    }
  }
}
