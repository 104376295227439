export class EnvironmentPermissions {
  public canArchiveEnvironment = false;
  public canUpdateEnvironmentSettings = false;
  public canCreateApiKey = false;
  public canArchiveApiKey = false;
  public canCreateConfig = false;
  public canArchiveConfig = false;
  public canArchiveConfigVersion = false;
  public canMoveConfig = false;
  public canCreateFolder = false;
  public canMoveFolder = false;
  public canDeleteFolder = false;
  public canAttachSchema = false;
  public canDetachSchema = false;
  public canCrudControlPanel = false;
  public canCrudVersionStable = false;

  public constructor(model: Partial<EnvironmentPermissions>) {
    Object.assign(this, model);
  }
}
