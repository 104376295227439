const ID = "chatwoot-script";

let initializationRequested = false;
let isReady = false;
const queue: ((chatwoot: ChatwootInstance) => void)[] = [];
declare global {
  interface Window {
    chatwootSettings: { type: string; launcherTitle: string };
  }
}





function initChatWoot() {
  if (initializationRequested) {
    return;
  }

  initializationRequested = true;
  window.chatwootSettings = { type: "expanded_bubble", launcherTitle: "Questions? Just ask!" };
  const BASE_URL = "https://app.chatwoot.com";
  const scriptElement = document.createElement("script");
  scriptElement.setAttribute("id", ID);
  scriptElement.src = BASE_URL + "/packs/js/sdk.js";
  scriptElement.defer = true;
  scriptElement.async = true;
  scriptElement.onload = function () {
    window.chatwootSDK.run({
      websiteToken: process.env.VUE_APP_CHATWOOT_WEBSITE_TOKEN,
      baseUrl: BASE_URL,
    });
  };
  window.addEventListener("chatwoot:ready", () => {
    const chatwoot = window.$chatwoot;
    if (!chatwoot) {
      throw new Error("Chatwoot should be initialized when the ready event is fired");
    }
    queue.forEach((fn) => {
      fn(chatwoot);
    });
    isReady = true;
  });
  document.getElementsByTagName("head")[0].appendChild(scriptElement);
}

export function hideChatWoot() {
  // Don't need to hide anything if it hasn't been initialized yet, it's not yet visible
  if (!initializationRequested) {
    return;
  }

  onChatwootReady((chatwoot: ChatwootInstance) => {
    chatwoot.toggleBubbleVisibility("hide");
    chatwoot.toggle("close");
  });
}

export function showChatWoot() {
  initChatWoot();
  onChatwootReady((chatwoot: ChatwootInstance) => {
    chatwoot.toggleBubbleVisibility("show");
  });
}
function onChatwootReady(fn: (chatwoot: ChatwootInstance) => void) {
  if (!isReady) {
    queue.push(fn);
  } else if (window.$chatwoot) {
    fn(window.$chatwoot);
  } else {
    throw new Error("Chatwoot should already be initialized when `isReady` flag is true");
  }
}
