import { User } from "./User";

export class BaseModel {
  public id = 0;
  public hashId = "";
  public organizationId = 0;
  public productId = 0;
  public environmentId = 0;
  public contentId = "";
  public createBy = 0;
  public updateBy = 0;
  public createdAt: Date = new Date();
  public updatedAt: Date = new Date();
  public createByUser: User = new User({});
  public updateByUser: User = new User({});
  public constructor(model: Partial<BaseModel>) {
    Object.assign(this, model);
    if (model && model.createByUser) {
      this.createByUser = new User(model.createByUser);
    }
    if (model && model.updateByUser) {
      this.updateByUser = new User(model.updateByUser);
    }
  }
}
