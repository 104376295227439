import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "joy-app-content",
    class: _normalizeClass(["joy-app-content", {
      _pinned: _ctx.isLeftSidebarPinned,
      _sidebar_disabled: _ctx.isLeftSidebarDisabled,
      _with_recent_logs: _ctx.isRecentLogsAvailable,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}