
import { Options, Vue } from "vue-class-component";
import { resetUser, resetUserConfirm } from "@/services/UserService";
import { DataResStatus } from "@/models/DataRes";
import { toastAdd } from "@/components/toasts/toastHelper";
import { LoadingStatus } from "@/directives/LoadingDirective";

@Options({
  components: {},
})
export default class ResetComponent extends Vue {
  email = "";
  password = "";
  passwordConfirmation = "";

  public status: LoadingStatus = LoadingStatus.None;

  get code(): string {
    return this.$route.params.code as string;
  }

  async reset(): Promise<void> {
    this.status = LoadingStatus.Loading;
    const initResetPasswordRes = await resetUser(this.email);
    if (initResetPasswordRes.status === DataResStatus.Ok) {
      this.$router.push({ name: "login" });
      toastAdd("An email with password reset instructions has been sent to you.", "Email sent!");
      this.status = LoadingStatus.None;
    } else {
      this.status = LoadingStatus.None;
    }
  }

  async resetConfirm(): Promise<void> {
    this.status = LoadingStatus.Loading;
    const resetPasswordRes = await resetUserConfirm(this.code, this.password, this.passwordConfirmation);
    if (resetPasswordRes.status === DataResStatus.Ok) {
      this.status = LoadingStatus.None;
      this.$router.push({ name: "login" });
      toastAdd("You may now login again.", "Password reset complete!");
    } else {
      this.status = LoadingStatus.None;
    }
  }
}
