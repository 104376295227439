import { ConfigDto } from "./ConfigDto";
import { ConfigFeatureGroup } from "./ConfigListPageModel";
import { ListPageModel } from "./ListPageModel";

export class ConfigListPageDto extends ListPageModel<ConfigDto> {
  public hasSchema = false;
  public featureGroupCount = 0;

  public featureGroups: ConfigFeatureGroup[] = [];

  public constructor(model: Partial<ConfigListPageDto>) {
    super(model);
    Object.assign(this, model);
  }

  public deepClone() {
    return new ConfigListPageDto({
      ...this,
      featureGroups: this.featureGroups.map((group) => ({
        ...group,
      })),
      page: this.page.map((config) => new ConfigDto({ ...config })),
    });
  }
}
