import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-62527c47"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", [_ctx.type]])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.icon),
            style: _normalizeStyle({ color: _ctx.iconColor })
          }, null, 6)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}