
import { Options, Vue } from "vue-class-component";
import { toastAdd } from "./toasts/toastHelper";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

library.add(faCopy);

@Options({
  props: {
    content: String,
    iconAlwaysVisible: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    confirmationText: {
      type: String,
      default: "Copied to clipboard.",
    },
  },
})
export default class CopyComponent extends Vue {
  public content!: string;
  public iconAlwaysVisible!: boolean;
  public inline!: boolean;
  public confirmationText!: string;

  public copyToClip() {
    if (this.copyToClipboard(this.content)) {
      toastAdd(this.confirmationText, "Great!");
    } else {
      toastAdd("Could not copy to clipboard, try copying manually.", "Oops!");
    }
  }

  private copyToClipboard(text: string): boolean {
    if ((window as any).clipboardData && (window as any).clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return (window as any).clipboardData.setData("Text", text) as boolean;
    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      const textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy") as boolean; // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
    return false;
  }
}
