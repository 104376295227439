import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = {
  key: 1,
  id: "main-router-view-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_CookieConsent = _resolveComponent("CookieConsent")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingIndicator),
    _createVNode(_component_CookieConsent),
    _createVNode(_component_Toast),
    (_ctx.store.getters.isAwaitingToken)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading"))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]))
  ], 64))
}