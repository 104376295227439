export type UserInvitation = {
  id: number;
  invitedBy: string;
  productId: number;
  productName: string;
  state: "NONE" | "PENDING" | "EXPIRED" | "REVOKED" | "ACCEPTED";
};

export type IUser = {
  hid: string; // HashId
  email: string;
  name: string;
  username: string;
  isEmailVerified: boolean;
  lastLoginAt?: string;
  userProduct?: any[];
};

export class User implements IUser {
  public hid = "";
  public email = "";
  public name = "";
  public username = "";
  public isEmailVerified = false;
  public lastLoginAt = "";
  public password? = "";
  public passwordConfirmation? = "";
  public invitationCode? = "";
  public invitations?: UserInvitation[]; // used for onboarding

  public constructor(model: Partial<User>) {
    Object.assign(this, model);
  }
}
