
import { Options, setup, Vue } from "vue-class-component";
import { useStore, State } from "@/store";
import { Store } from "vuex";
import Toast from "@/components/toasts/Toast.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CookieConsent from "@/components/CookieConsent.vue";

@Options({
  components: {
    Toast,
    LoadingIndicator,
    CookieConsent,
  },
})
export default class AppComponent extends Vue {
  store = setup<Store<State>>(() => useStore());
}
