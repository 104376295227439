import { BaseModel } from "../BaseModel";

export class Product extends BaseModel {
  public name = "";
  public maxConfigSize = 0;
  public organizationContentId = "";

  public constructor(model: Partial<Product>) {
    super(model);
    Object.assign(this, model);
  }

  public get iconName(): string {
    return this.name[0] + this.name.slice(-1);
  }
}
