
import { Options, Vue } from "vue-class-component";
import { JoystickControlPanelSchema } from "./JoystickControlPanelSchema";
import { randomUid } from "@/helpers/random-uid";

@Options({
  props: {
    schema: JSON,
    value: Boolean,
    panelItemKey: String,
  },
  name: "control-panel-boolean",
  emits: ["update"],
})
export default class ControlPanelBoolean extends Vue {
  public schema!: JoystickControlPanelSchema;
  public value!: boolean;
  public panelItemKey!: string;

  public valueInComponent = "";
  public valueToAssign = false;
  public randomRadioGroupName = "";
  public labelTrue = "True";
  public labelFalse = "False";

  public inputDisabled = false;

  public onChange(): void {
    this.valueToAssign = this.valueInComponent == "true" ? true : false;
    this.$emit("update", this.valueToAssign);
  }

  created(): void {
    this.inputDisabled = this.schema?.cp_widget_options?.disabled ? true : false;

    this.valueInComponent = typeof this.value === "boolean" ? `${this.value}` : ""; // if the value is set, use that. If it is not set, do not set an explicit value.
    this.randomRadioGroupName = randomUid(); // Generate a random radio group. This is for when we have arrays.

    this.labelTrue = this.schema?.cp_widget_options?.label_true ? this.schema?.cp_widget_options?.label_true : "True";
    this.labelFalse =
      this.schema?.cp_widget_options?.label_false ? this.schema?.cp_widget_options?.label_false : "False";
  }
}
