import { VersionStable } from "@/models/VersionStable";

export type VersionStableState = {
  activeVersionStable: VersionStable | null;
};

const versionStableState = (): VersionStableState => ({
  activeVersionStable: null,
});

const getters = {
  activeVersionStable: (state: VersionStableState) => state.activeVersionStable,
};

const actions = {};

const mutations = {
  setActiveVersionStable(state: VersionStableState, versionStable: VersionStable) {
    state.activeVersionStable = versionStable;
  },
};

export default {
  namespaced: true,
  state: versionStableState,
  getters,
  actions,
  mutations,
};
