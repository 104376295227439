
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    type: {
      type: String,
      default: "info",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
  },
})
export default class Alert extends Vue {
  public type!: string;
  public icon!: string;
  public iconColor!: string;
}
