import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-799835fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selector" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "dropdown" }
const _hoisted_4 = { class: "options" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayValue) = $event)),
        type: "text",
        placeholder: "Select",
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterOptions()))
      }, null, 544), [
        [_vModelText, _ctx.displayValue]
      ]),
      (_ctx.selectedOption.value)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "remove",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeSelectedOption()))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "times" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, { icon: "caret-down" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (o) => {
          return (_openBlock(), _createElementBlock("li", {
            key: o.value,
            onClick: ($event: any) => (_ctx.setSelectedOption(o))
          }, _toDisplayString(o.label), 9, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}