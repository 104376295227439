import { ConfigDataType } from "./Config";
import { User } from "./User";
import { getSchemaValues } from "@/components/controlPanel/ControlPanelHelper";

export class ConfigDto {
  public hid = "";
  public contentId = "";
  public description = "";
  public dataType: ConfigDataType = ConfigDataType.Json;
  public folderHid = "";
  public data = "{}";
  public version = 1;
  public hash = "";
  public source = "cms";
  public staticLookupEntries = "[]";
  public staticLookupHash = "";
  public isNewVersionRequest = false;
  public validateJson = true;
  public createdAt: Date = new Date();
  public updatedAt: Date = new Date();
  public hasSchema = false;

  public createByUser: User = new User({});
  public updateByUser: User = new User({});

  public constructor(model: Partial<ConfigDto>) {
    Object.assign(this, model);
  }

  public getDataForControlPanel(): JSON {
    let ret = JSON.parse("{}");
    try {
      const dataObject = JSON.parse(this.getDataWithoutJsonComments());
      ret = getSchemaValues(dataObject, /(#CP-)([^:]*?):(.*?)#/);
    } catch (err) {
      console.log("Could not parse the content config to extract the Control Panel declarations.");
    }
    // console.log('Data for Control Panel: ', ret);
    // This returns an object with all of the Control Panel declarations, flattened. {"CP-abc":{}, "CP-efg":{}}
    return ret;
  }

  public getDataWithoutJsonComments(): string {
    return this.data.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m, g) => (g ? "" : m));
  }
}
