import { BaseModel } from "./BaseModel";

export class PushRequestActivityLog extends BaseModel {
  public pushRequestId = "";
  public action = PushRequestActivityLogAction.None;
  public message = "";

  public constructor(model: Partial<PushRequestActivityLog>) {
    super(model);
    Object.assign(this, model);
  }

  public get actionName() {
    switch (this.action) {
      case PushRequestActivityLogAction.Approve:
        return "Approved";
      case PushRequestActivityLogAction.Open:
        return "Opened";
      case PushRequestActivityLogAction.RevokeApprove:
        return "Approval Revoked";
      case PushRequestActivityLogAction.Close:
        return "Closed";
      case PushRequestActivityLogAction.Push:
        return "Pushed";
      default:
        return "";
    }
  }

  public get actionIcon() {
    switch (this.action) {
      case PushRequestActivityLogAction.Approve:
        return "checkbox-circle-line";
      case PushRequestActivityLogAction.Open:
        return "loader-2-line";
      case PushRequestActivityLogAction.RevokeApprove:
        return "error-warning-line";
      case PushRequestActivityLogAction.Close:
        return "delete-bin-4-line";
      case PushRequestActivityLogAction.Push:
        return "send-plane-2-line";
      default:
        return "";
    }
  }
}

export enum PushRequestActivityLogAction {
  None = 0,
  Open = 1,
  Approve = 2,
  RevokeApprove = 3,
  Close = 4,
  Push = 5,
}
