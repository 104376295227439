
import ControlPanelBoolean from "@/components/controlPanel/ControlPanelBoolean.vue";
import ControlPanelNumber from "@/components/controlPanel/ControlPanelNumber.vue";
import ControlPanelString from "@/components/controlPanel/ControlPanelString.vue";
import { Options, Vue } from "vue-class-component";
import { JoystickControlPanelSchema } from "./JoystickControlPanelSchema";

@Options({
  props: {
    schema: JSON,
    value: Object,
    panelItemKey: String,
  },
  components: {
    "control-panel-boolean": ControlPanelBoolean,
    "control-panel-number": ControlPanelNumber,
    "control-panel-string": ControlPanelString,
  },
  name: "control-panel-object",
  emits: ["update"],
})
export default class ControlPanelObject extends Vue {
  public schema!: JoystickControlPanelSchema;
  public value!: Record<string, unknown>;
  public panelItemKey!: string;

  public valueInComponent = {};
  public trackingKey = 0;

  created(): void {
    // keep a copy of the object here first.
    if (typeof this.value === "object" && this.value !== null) {
      this.valueInComponent = JSON.parse(JSON.stringify(this.value));
    }
  }
  public updateConfig(key: string, value: Record<string, unknown>): void {
    this.valueInComponent[key] = value;
    this.$emit("update", this.valueInComponent);
  }
  public getAttributeValue(key: string, itemType: string): unknown {
    if (typeof this.value === "undefined") {
      switch (itemType) {
        case "string":
          return "";
        case "number":
          return NaN;
        case "boolean":
          return null;
        case "array":
          return [];
        case "object":
          return {};
        default:
          console.log("ERROR: Invalid item type defined in schema.");
      }
    } else {
      // console.log('Setting object child item value: ', this.value[key]);
      return this.value[key];
    }
  }
}
