import { BaseModel } from "../BaseModel";

export class Environment extends BaseModel {
  public hid = "";
  public name = "";
  public description = "";
  public type = EnvironmentType.None;
  public color = EnvironmentColor.None;
  public icon = EnvironmentIcon.None;
  public pushProtected = false;
  public pushProtectedApprovalsRequired = 1;

  public constructor(model: Partial<Environment>) {
    super(model);
    Object.assign(this, model);
  }

  public get colorVal() {
    const currentColor = ENV_COLOR_MAP[this.color];
    return currentColor ? `#${currentColor}` : `#${ENV_COLOR_MAP[EnvironmentColor.Default]}`;
  }

  public get iconVal() {
    return this.icon ? ENV_ICON_MAP[this.icon] : "code-s-slash-line";
  }

  infoIsValid() {
    if (this.name.length < 2 || this.name.length > 50 || this.description.length < 2 || this.description.length > 140) {
      return false;
    }

    return true;
  }
}

export enum EnvironmentType {
  None = 0,
  Development = 1,
  QA = 2,
  Staging = 3,
  Production = 4,
}

export enum EnvironmentColor {
  None = 0,
  Default = 1,
  Red10 = 10,
  Orange20 = 20,
  Yellow30 = 30,
  Green40 = 40,
  Cyan50 = 50,
  Aqua60 = 60,
  Blue70 = 70,
  Purple80 = 80,
  Brown90 = 90,
}

export enum EnvironmentIcon {
  None = 0,
  Home4Line = 1,
  Database2Line = 2,
  BarChart2Line = 3,
  PieChartLine = 4,
  BubbleChartLine = 5,
  Bookmark3Line = 6,
  Flag2Line = 7,
  ToolsLine = 8,
  BugLine = 9,
  FlaskLine = 10,
  EqualizerLine = 11,
  Settings3Line = 12,
  Dashboard3Line = 13,
  CodeBoxLine = 14,
  GitPullRequestLine = 16,
  GitMergeLine = 17,
  GitBranchLine = 18,
  BaseStationLine = 19,
  VipCrown2Line = 20,
  EarthLine = 21,
  Rocket2Line = 22,
  PaletteLine = 23,
  ImageLine = 24,
  StarLine = 25,
  StarHalfLine = 26,
  StarFill = 27,
  AlertLine = 28,
  InboxArchiveLine = 29,
  UploadCloudLine = 30,
  DownloadCloudLine = 31,
  DeleteBinLine = 32,
  LockLine = 33,
  ShareLine = 34,
  GamepadLine = 35,
  GameLine = 36,
}

export const ENV_COLOR_MAP = {
  [EnvironmentColor.None]: "101729",
  [EnvironmentColor.Default]: "455A63",
  [EnvironmentColor.Red10]: "C66074",
  [EnvironmentColor.Orange20]: "B46D55",
  [EnvironmentColor.Yellow30]: "C1AB5E",
  [EnvironmentColor.Green40]: "529456",
  [EnvironmentColor.Cyan50]: "4495A0",
  [EnvironmentColor.Aqua60]: "4878A4",
  [EnvironmentColor.Blue70]: "4D63B8",
  [EnvironmentColor.Purple80]: "6D4EA8",
  [EnvironmentColor.Brown90]: "616161",
};

export const ENV_ICON_MAP = {
  [EnvironmentIcon.None]: "code-s-slash-line",
  [EnvironmentIcon.Home4Line]: "home-4-line",
  [EnvironmentIcon.Database2Line]: "database-2-line",
  [EnvironmentIcon.BarChart2Line]: "bar-chart-2-line",
  [EnvironmentIcon.PieChartLine]: "pie-chart-line",
  [EnvironmentIcon.BubbleChartLine]: "bubble-chart-line",
  [EnvironmentIcon.Bookmark3Line]: "bookmark-3-line",
  [EnvironmentIcon.Flag2Line]: "flag-2-line",
  [EnvironmentIcon.ToolsLine]: "tools-line",
  [EnvironmentIcon.BugLine]: "bug-line",
  [EnvironmentIcon.FlaskLine]: "flask-line",
  [EnvironmentIcon.EqualizerLine]: "equalizer-line",
  [EnvironmentIcon.Settings3Line]: "settings-3-line",
  [EnvironmentIcon.Dashboard3Line]: "dashboard-3-line",
  [EnvironmentIcon.CodeBoxLine]: "code-box-line",
  [EnvironmentIcon.GitPullRequestLine]: "git-pull-request-line",
  [EnvironmentIcon.GitMergeLine]: "git-merge-line",
  [EnvironmentIcon.GitBranchLine]: "git-branch-line",
  [EnvironmentIcon.BaseStationLine]: "base-station-line",
  [EnvironmentIcon.VipCrown2Line]: "vip-crown-2-line",
  [EnvironmentIcon.EarthLine]: "earth-line",
  [EnvironmentIcon.Rocket2Line]: "rocket-2-line",
  [EnvironmentIcon.PaletteLine]: "palette-line",
  [EnvironmentIcon.ImageLine]: "image-line",
  [EnvironmentIcon.StarLine]: "star-line",
  [EnvironmentIcon.StarHalfLine]: "star-half-line",
  [EnvironmentIcon.StarFill]: "star-fill",
  [EnvironmentIcon.AlertLine]: "alert-line",
  [EnvironmentIcon.InboxArchiveLine]: "inbox-archive-line",
  [EnvironmentIcon.UploadCloudLine]: "upload-cloud-line",
  [EnvironmentIcon.DownloadCloudLine]: "download-cloud-line",
  [EnvironmentIcon.DeleteBinLine]: "delete-bin-line",
  [EnvironmentIcon.LockLine]: "lock-line",
  [EnvironmentIcon.ShareLine]: "share-line",
  [EnvironmentIcon.GamepadLine]: "gamepad-line",
  [EnvironmentIcon.GameLine]: "game-line",
};
