
import { Options, Vue } from "vue-class-component";
import ControlPanelBoolean from "@/components/controlPanel/ControlPanelBoolean.vue";
import ControlPanelNumber from "@/components/controlPanel/ControlPanelNumber.vue";
import ControlPanelString from "@/components/controlPanel/ControlPanelString.vue";
import { JoystickControlPanelSchema } from "./JoystickControlPanelSchema";

@Options({
  props: {
    schema: JSON,
    value: Array,
    panelItemKey: String,
  },
  components: {
    "control-panel-boolean": ControlPanelBoolean,
    "control-panel-number": ControlPanelNumber,
    "control-panel-string": ControlPanelString,
  },
  name: "control-panel-array",
  emits: ["update"],
})
export default class ControlPanelArray extends Vue {
  public schema!: JoystickControlPanelSchema;
  public value!: Array<unknown>;
  public panelItemKey!: string;

  public valueInComponent: Array<unknown> = [];
  public trackingKey = 0;
  public arrayItemNoun = "Item";

  created(): void {
    this.valueInComponent = typeof this.value === "undefined" ? [] : JSON.parse(JSON.stringify(this.value));
    try {
      if (this.schema?.cp_widget_options?.array_item_noun) {
        this.arrayItemNoun = this.schema.cp_widget_options.array_item_noun;
      }
    } catch (err) {
      this.arrayItemNoun = "Item";
    }
  }

  public updateConfig(key: string, newItemValue: Record<string, unknown>): void {
    // console.log('Updating Array', this.valueInComponent);
    this.valueInComponent[key] = newItemValue;
    this.$emit("update", this.valueInComponent);
  }
  public getAttributeValue(index: number): unknown {
    if (index > this.valueInComponent.length - 1 && this.schema && this.schema.items) {
      // Note: Duplicated in ControlPanelObject.vuec
      switch (this.schema.items.type) {
        case "string":
          return "";
        case "number":
          return NaN;
        case "boolean":
          return null;
        case "array":
          return [];
        case "object":
          return {};
        default:
          console.log("Invalid item type defined in schema.");
      }
    } else {
      return this.valueInComponent[index];
    }
  }
  public addItem(): void {
    this.valueInComponent.push(this.getAttributeValue(99999)); // push in an empty object of the appropriate type.
  }
  public removeItem(idx: number): void {
    this.valueInComponent.splice(idx, 1);
    this.$emit("update", this.valueInComponent);
    this.trackingKey += 1; // increment the tracking key everytime we perform an action on the array so that VUE will re-render it!
  }

  public swapArrayItem(arr: Array<any>, from: number, to: number): Array<any> {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }

  public moveArrayItem(idx: number, dir: string): void {
    dir == "up" ?
      this.swapArrayItem(this.valueInComponent, idx, idx - 1)
    : this.swapArrayItem(this.valueInComponent, idx, idx + 1);
    this.$emit("update", this.valueInComponent);
    this.trackingKey++;
  }
}
