
import { defineComponent } from "vue";
import JoystickTextLogo from "@/components/svgIcons/JoystickTextLogo.vue";
import { toastAdd } from "@/components/toasts/toastHelper";
import { config as clientAppConfig } from "@/config";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faCodePullRequest, faBook } from "@fortawesome/free-solid-svg-icons";

library.add(faDiscord, faCodePullRequest, faBook);

export default defineComponent({
  name: "AppHeader",
  components: {
    JoystickTextLogo,
  },
  computed: {
    rightSideBlockStyles() {
      return (
        this.$route.path.includes("env") && {
          backgroundColor: this.$store.getters.environment.colorVal,
        }
      );
    },
    clientAppVersion() {
      return clientAppConfig.appVersion;
    },
  },
  methods: {
    logout(): void {
      this.$store.dispatch("tokenLogout");
      this.$router.push({
        name: "login",
        query: { continue: this.$route.path },
      });
      toastAdd("You have logged out successfully. See you soon!", "Log Out Success");
    },
  },
});
