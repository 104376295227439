
import { Options, Vue } from "vue-class-component";
import { JoystickControlPanelSchema } from "./JoystickControlPanelSchema";
import { randomUid } from "@/helpers/random-uid";

@Options({
  props: {
    schema: JSON,
    value: Number,
    panelItemKey: String,
  },
  name: "control-panel-number",
  emits: ["update"],
})
export default class ControlPanelNumber extends Vue {
  public schema!: JoystickControlPanelSchema;
  public value!: number;
  public panelItemKey!: string;

  public valueInComponent = NaN;
  public randomRadioGroupName = "";
  public inputDisabled = false;
  public inputTypeToShow = "number";
  public placeholder = "";

  public onChange(): void {
    if (isNaN(this.valueInComponent)) {
      this.valueInComponent = NaN;
    } else {
      this.$emit("update", Number(this.valueInComponent));
    }
  }

  created(): void {
    if ((this.value || this.value === 0) && !isNaN(this.value)) {
      this.valueInComponent = this.value;
    }
    this.inputDisabled = this.schema?.cp_widget_options?.disabled ? true : false;

    if (this.schema?.cp_widget_options?.input_type) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.schema.cp_widget_options.input_type == "radio" && this.schema?.enum!.length > 0) {
        this.inputTypeToShow = "radio";
        this.randomRadioGroupName = randomUid(); // Generate a random radio group. This is for when we have arrays.
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (this.schema.cp_widget_options.input_type == "select" && this.schema?.enum!.length > 0) {
        this.inputTypeToShow = "select";
      }
    }

    this.placeholder = this.schema?.cp_widget_options?.placeholder ? this.schema?.cp_widget_options?.placeholder : "";
  }
}
