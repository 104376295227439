
import { setup, Vue } from "vue-class-component";
import { loginUserAndRedirect } from "@/services/UserService";
import { useStore, State } from "@/store";
import { Store } from "vuex";
import { DataResStatus } from "@/models/DataRes";
import { toastAdd } from "@/components/toasts/toastHelper";
import { LoadingStatus } from "@/directives/LoadingDirective";
import { defaultLogger } from "@/services/LoggerService";

export default class OnboardingComponent extends Vue {
  email = "";
  password = "";
  store = setup<Store<State>>(() => useStore());
  loadingStatus: LoadingStatus = LoadingStatus.None;

  async login(): Promise<void> {
    this.loadingStatus = LoadingStatus.Loading;
    const loggedIn = await loginUserAndRedirect(this.email, this.password, this.$router);
    if (loggedIn.status === DataResStatus.Ok) {
      toastAdd("Welcome to Joystick.", "Hello!");
    }
    this.loadingStatus = LoadingStatus.Success;
  }

  public mounted(): void {
    defaultLogger.info("Navigated to login page");
  }
}
