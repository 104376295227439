import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "panel-array-wrapper" }
const _hoisted_2 = { class: "panel-array-items-top" }
const _hoisted_3 = { class: "panel-array-title" }
const _hoisted_4 = { class: "panel-array-description" }
const _hoisted_5 = { class: "panel-array-items" }
const _hoisted_6 = { class: "panel-array-item-bottom" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "panel-array-item-action-disabled"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 3,
  class: "panel-array-item-action-disabled"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "panel-array-items-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.schema.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.schema.description), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueInComponent, (existingItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: _ctx.trackingKey + '_' + index,
          class: "panel-array-item"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(`control-panel-${_ctx.schema.items.type}`), {
            schema: _ctx.schema.items,
            "panel-item-key": _ctx.panelItemKey + ' ' + index,
            value: _ctx.getAttributeValue(index),
            onUpdate: ($event: any) => (_ctx.updateConfig(index, $event))
          }, null, 40, ["schema", "panel-item-key", "value", "onUpdate"])),
          _createElementVNode("div", _hoisted_6, [
            (index > 0)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  class: "panel-array-item-action",
                  onClick: _withModifiers(($event: any) => (_ctx.moveArrayItem(index, 'up')), ["prevent"])
                }, " ↑ Up ", 8, _hoisted_7))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, "↑ Up")),
            (index < _ctx.valueInComponent.length - 1)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: "#",
                  class: "panel-array-item-action",
                  onClick: _withModifiers(($event: any) => (_ctx.moveArrayItem(index, 'down')), ["prevent"])
                }, " ↓ Down ", 8, _hoisted_9))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, "↓ Down")),
            _createElementVNode("a", {
              href: "#",
              class: "panel-array-item-action",
              onClick: _withModifiers(($event: any) => (_ctx.removeItem(index)), ["prevent"])
            }, " Χ Remove " + _toDisplayString(_ctx.arrayItemNoun), 9, _hoisted_11)
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("button", {
        class: "panel-array-item-add",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addItem && _ctx.addItem(...args)), ["prevent"]))
      }, "+ Add " + _toDisplayString(_ctx.arrayItemNoun), 1)
    ])
  ]))
}