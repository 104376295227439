
import { UserInvitation } from "@/models/User";
import { Options, Vue } from "vue-class-component";
import capitalize from "lodash/capitalize";

@Options({
  props: {
    invitation: Object,
  },
})
export default class InvitationCardComponent extends Vue {
  public invitation!: UserInvitation;

  public get state() {
    return capitalize(this.invitation.state.toLowerCase() == "accepted" ? "Accepted" : "Not Valid");
  }

  public get accepted() {
    return this.invitation.state === "ACCEPTED";
  }
}
