import { Logger, datadogLogs } from "@datadog/browser-logs";
import { config } from "@/config";

function configureDatadogLogger(logger: Logger) {
  logger.setHandler(["http", "console"]);
}
export const initLogger = () => {
  if (process.env.VUE_APP_DATADOG_ENABLED === "true") {
    datadogLogs.init({
      clientToken: "pubf30eee58025dbc15ab4a911767d4fe2c",
      service: "opsdeck-cms-client",
      version: config.appVersion,
      env: config.appEnv,
      forwardErrorsToLogs: true,
      telemetrySampleRate: 0, // Don't send telemetry back to datadog
      beforeSend: (log) => {
        if (log.message == "ResizeObserver loop limit exceeded") {
          return false; // Suppress this. Happens on configversions.vue. Spams the logs.
        }
        return true;
      },
    });

    configureDatadogLogger(datadogLogs.logger);
  }
  return datadogLogs;
};

export function loggerFor(group: string) {
  let logger = datadogLogs.getLogger(group);

  if (!logger) {
    logger = datadogLogs.createLogger(group, { context: { group: group } });
    configureDatadogLogger(logger);
  }

  return logger;
}

export const defaultLogger = datadogLogs.logger;
