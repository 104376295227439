export class UserToken {
  public accessToken = "";
  public refreshToken: UserRefreshToken = new UserRefreshToken({});

  public constructor(model: Partial<UserToken>) {
    Object.assign(this, model);
    if (model.refreshToken) {
      this.refreshToken = new UserRefreshToken(model.refreshToken);
    }
  }
}

export class UserRefreshToken {
  public expiresAt = "";
  public tokenString = "";
  public userName = "";
  public constructor(model: Partial<UserRefreshToken>) {
    Object.assign(this, model);
  }
}
