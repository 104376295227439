<template>
  <svg width="109" height="23" viewBox="0 0 109 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.8455 15.1562H21.0462C21.3153 15.245 21.5921 15.3084 21.8729 15.3456C22.1979 15.3968 22.5264 15.423 22.8554 15.424C23.7188 15.424 24.3738 15.2155 24.8204 14.7986C25.2671 14.3817 25.4907 13.756 25.4913 12.9216V1.72638L28.6606 2.37819V12.8996C28.6606 14.0464 28.4335 15.0033 27.9792 15.7702C27.5348 16.5292 26.8683 17.1337 26.0697 17.5019C25.248 17.8894 24.2878 18.0832 23.1891 18.0832C22.7597 18.0832 22.3308 18.057 21.9046 18.0048C21.5472 17.9649 21.1929 17.9016 20.8438 17.8154L20.8455 15.1562Z"
      fill="white"
    />
    <path
      d="M31.0298 11.67C31.0298 10.2842 31.283 9.11857 31.7896 8.17316C32.2616 7.26114 33.0023 6.51616 33.9113 6.03893C34.8186 5.56211 35.8607 5.324 37.0375 5.32459C38.199 5.32459 39.2338 5.5627 40.1417 6.03893C41.0505 6.51648 41.791 7.26138 42.2634 8.17316C42.7699 9.11916 43.0232 10.2848 43.0232 11.67C43.0232 13.0705 42.7699 14.2399 42.2634 15.1783C41.787 16.0872 41.0475 16.831 40.1417 17.3125C39.2343 17.797 38.1996 18.0389 37.0375 18.0383C35.846 18.0383 34.8002 17.7964 33.8998 17.3125C32.9977 16.8291 32.2615 16.0855 31.7869 15.1783C31.2816 14.2399 31.0292 13.0705 31.0298 11.67ZM34.2238 11.67C34.2238 12.9813 34.4621 13.9684 34.9387 14.6313C35.4153 15.2943 36.1149 15.6258 37.0375 15.6258C37.946 15.6258 38.6383 15.2943 39.1143 14.6313C39.5903 13.9684 39.8286 12.9813 39.8292 11.67C39.8292 10.3594 39.5909 9.37254 39.1143 8.70958C38.6377 8.04661 37.9455 7.71513 37.0375 7.71513C36.1137 7.71513 35.4141 8.04661 34.9387 8.70958C34.4633 9.37254 34.225 10.3594 34.2238 11.67V11.67Z"
      fill="white"
    />
    <path
      d="M56.2008 5.61523V5.883L49.5451 23H46.4409V22.7313L48.3909 18.0462L43.761 5.883V5.61523H47.0888L49.9386 14.1319L53.0296 5.61523H56.2008Z"
      fill="white"
    />
    <path
      d="M57.228 14.7318H57.4041C58.0154 14.9782 58.6466 15.1724 59.2907 15.3122C60.0052 15.4757 60.7358 15.5581 61.4688 15.558C61.7214 15.558 61.997 15.5474 62.2946 15.5245C62.5782 15.505 62.8591 15.4563 63.1327 15.3792C63.3831 15.3118 63.6082 15.1721 63.7798 14.9775C63.9511 14.7837 64.0368 14.523 64.0368 14.1954C64.0368 13.779 63.8658 13.4886 63.5236 13.3242C63.1814 13.1598 62.6532 13.0183 61.9389 12.8997L60.8886 12.7235C59.6977 12.515 58.7821 12.1536 58.1418 11.6392C57.5015 11.1248 57.1813 10.3614 57.1813 9.34908C57.1745 8.64173 57.3806 7.9487 57.7729 7.36019C58.1673 6.76476 58.7854 6.29176 59.627 5.94119C60.4687 5.59062 61.5741 5.41446 62.9434 5.4127C63.5376 5.40961 64.1311 5.45083 64.7191 5.53601C65.2442 5.61384 65.7629 5.72952 66.2712 5.88218V8.45593H66.0952C65.5692 8.24419 65.0228 8.08717 64.4647 7.98733C63.9337 7.89667 63.3958 7.85159 62.8571 7.85257C62.0225 7.85257 61.371 7.95328 60.9027 8.15469C60.4343 8.3561 60.1996 8.7099 60.1984 9.21608C60.1897 9.39164 60.2359 9.56556 60.3305 9.71363C60.4252 9.86171 60.5637 9.97659 60.7266 10.0423C61.0788 10.2073 61.5888 10.3488 62.2567 10.4668L63.2841 10.643C63.9443 10.7361 64.5875 10.9245 65.1937 11.2023C65.7383 11.4474 66.2111 11.828 66.5671 12.3078C66.9098 12.7775 67.0809 13.3771 67.0803 14.1064C67.0803 15.403 66.6002 16.3751 65.64 17.0228C64.6798 17.6705 63.2319 17.9934 61.2962 17.9917C60.5777 17.9921 59.8604 17.9332 59.1516 17.8155C58.502 17.7111 57.8604 17.5616 57.2315 17.3681L57.228 14.7318Z"
      fill="white"
    />
    <path
      d="M76.7256 15.1343V17.6367C76.4227 17.7506 76.1101 17.8367 75.7916 17.8939C75.3935 17.9643 74.9897 17.9979 74.5854 17.9943C73.529 17.9943 72.6319 17.8378 71.8941 17.5248C71.1929 17.2422 70.6048 16.7356 70.2214 16.0838C69.8422 15.4378 69.6524 14.5999 69.6518 13.5699V8.11765H67.7026V5.61524H69.6456V2.46631H72.7719V5.617H76.0997V8.11941H72.7719V13.3259C72.7719 14.0858 72.9506 14.6295 73.308 14.9572C73.6654 15.2849 74.2166 15.4487 74.9614 15.4487C75.4976 15.4465 76.0282 15.3403 76.524 15.136L76.7256 15.1343Z"
      fill="white"
    />
    <path
      d="M84.6536 11.6699C84.6536 10.2553 84.9441 9.0785 85.5251 8.13955C86.0973 7.20756 86.9379 6.47061 87.9365 6.02558C88.9636 5.55581 90.1548 5.32092 91.51 5.32092C92.5823 5.32092 93.492 5.46244 94.2391 5.74548V8.31483H94.0155C93.7066 8.16642 93.3817 8.05411 93.0471 7.98012C92.6256 7.88571 92.1946 7.84049 91.7626 7.84535C90.5424 7.84535 89.586 8.16832 88.8935 8.81425C88.2009 9.46019 87.8546 10.4097 87.8546 11.6628C87.8546 12.9142 88.2009 13.864 88.8935 14.5123C89.586 15.1606 90.5424 15.4835 91.7626 15.4812C92.1945 15.4863 92.6256 15.4414 93.0471 15.3473C93.3817 15.2733 93.7066 15.161 94.0155 15.0126H94.2383V17.5819C93.4946 17.8796 92.5849 18.0285 91.5091 18.0285C90.1105 18.0285 88.9008 17.7936 87.8802 17.3239C86.8879 16.8785 86.0577 16.137 85.5031 15.2011C84.9368 14.2621 84.6536 13.0851 84.6536 11.6699Z"
      fill="white"
    />
    <path
      d="M108.037 17.5477V17.812H104.442L99.9308 12.0752V17.812H96.8037V4.60937C96.8037 4.31165 96.7964 3.9282 96.7817 3.45902C96.767 2.98984 96.7482 2.53181 96.7253 2.08494C96.7155 1.72315 96.6668 1.36347 96.5801 1.0121L96.647 0.835938L99.9308 1.30101V10.8438L104.554 5.61527H107.899V5.87951C107.712 6.03464 107.535 6.2027 107.371 6.38246L106.734 7.06421C106.511 7.30204 106.294 7.53369 106.086 7.75654L102.756 11.3195L106.243 15.6002C106.525 15.9432 106.841 16.3081 107.192 16.6951C107.457 16.996 107.739 17.2807 108.037 17.5477Z"
      fill="white"
    />
    <path
      d="M78.873 17.8154V9.39126C78.873 9.09354 78.8654 8.71361 78.8501 8.25147C78.8349 7.78934 78.8164 7.33131 78.7947 6.8774C78.7851 6.51181 78.7364 6.1483 78.6494 5.79311L78.7163 5.61694H81.9993V17.8154H78.873Z"
      fill="white"
    />
    <path
      d="M80.4331 3.92673C79.8585 3.92673 79.381 3.7535 79.0007 3.40705C78.6204 3.06059 78.4311 2.57438 78.4329 1.94841C78.4329 1.33947 78.623 0.861772 79.0034 0.515317C79.3837 0.168861 79.8603 -0.00289906 80.4331 3.70093e-05C81.0235 3.70093e-05 81.5051 0.173265 81.8778 0.519721C82.2505 0.866177 82.4363 1.34387 82.4351 1.95281C82.4351 2.57878 82.2493 3.065 81.8778 3.41145C81.5063 3.75791 81.0247 3.92967 80.4331 3.92673Z"
      fill="url(#paint0_linear_2416_31458)"
    />
    <path d="M104.474 2.09635V0.308463H103.864V0H105.471V0.308463H104.857V2.09635H104.474Z" fill="white" />
    <path
      d="M105.755 2.09635V0H106.21L106.877 1.32969L107.536 0H107.991V2.09635H107.608V0.649869L107.024 1.79687H106.722L106.138 0.649869V2.09635H105.755Z"
      fill="white"
    />
    <path d="M13.542 22.9999H5.88074L10.4492 14.1933C10.4492 14.1933 8.25546 19.4858 13.542 22.9999Z" fill="#80DEEA" />
    <path
      d="M13.022 2.75267C13.022 2.81182 13.022 2.86966 13.0135 2.92684L12.4495 8.93571C12.4297 9.15086 12.3438 9.35455 12.2036 9.51865L10.9103 11.0388L9.75342 12.2967C9.49855 12.5735 9.28135 12.8829 9.10744 13.2168L4.31995 22.4322C4.23142 22.6034 4.09759 22.747 3.93311 22.8471C3.76863 22.9473 3.57984 23.0002 3.38738 23H1.7052C1.45539 23 1.20863 22.945 0.982367 22.8389C0.756108 22.7328 0.555879 22.5781 0.395843 22.3859C0.235808 22.1937 0.119874 21.9686 0.0562429 21.7265C-0.00738829 21.4844 -0.0171627 21.2313 0.0276111 20.985L1.66323 11.9444L4.95872 9.71713C4.97731 9.70447 4.99082 9.68563 4.99687 9.66394C5.00291 9.64226 5.0011 9.61912 4.99175 9.59865C4.98239 9.57817 4.96611 9.56168 4.94578 9.55208C4.92545 9.54249 4.90238 9.54043 4.88068 9.54625L1.70651 10.4079L0.985108 6.65453C0.856152 5.98233 0.981033 5.28609 1.33551 4.70097C1.68998 4.11584 2.24877 3.68358 2.90338 3.48809L3.70112 3.24897C3.94822 3.1749 4.21061 3.24594 4.44272 3.35848C4.55153 3.41124 4.66658 3.45121 4.78561 3.47726C5.04032 3.533 5.30497 3.52316 5.55486 3.44866L9.38813 2.30183C9.63799 2.22707 9.86484 2.09 10.0474 1.90345C10.1375 1.81141 10.2154 1.70882 10.2795 1.59825C10.4029 1.38569 10.5751 1.19248 10.8106 1.12214C11.0655 1.04303 11.3354 1.02501 11.5986 1.0695C11.8618 1.114 12.1108 1.21978 12.3258 1.37832C12.5407 1.53687 12.7155 1.74377 12.8361 1.98236C12.9567 2.22095 13.0197 2.48457 13.0201 2.75202L13.022 2.75267Z"
      fill="#FF6F00"
    />
    <path
      d="M9.73443 0.589037C9.79656 0.797764 9.77343 1.02267 9.67013 1.21432C9.56682 1.40596 9.3918 1.54864 9.18354 1.611L5.35028 2.75783C5.24693 2.78868 5.13853 2.79884 5.03126 2.78771C4.924 2.77658 4.81997 2.74439 4.72512 2.69298C4.63028 2.64156 4.54646 2.57193 4.47847 2.48805C4.41047 2.40417 4.35963 2.3077 4.32884 2.20413C4.29805 2.10056 4.28792 1.99193 4.29902 1.88444C4.31012 1.77695 4.34225 1.6727 4.39355 1.57765C4.44486 1.4826 4.51435 1.39861 4.59805 1.33047C4.68175 1.26233 4.77802 1.21138 4.88137 1.18053L8.71463 0.033694C8.92335 -0.0280759 9.14801 -0.00428059 9.33923 0.0998513C9.53045 0.203983 9.6726 0.379932 9.73443 0.589037Z"
      fill="#FFAB00"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2416_31458"
        x1="80.3091"
        y1="0.0527954"
        x2="80.3091"
        y2="3.7992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF984E" />
        <stop offset="1" stop-color="#FF2E00" />
      </linearGradient>
    </defs>
  </svg>
</template>
