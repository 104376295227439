import { Config } from "./Config";

export class ConfigModel extends Config {
  public hasSchema = false;

  public constructor(model: Partial<ConfigModel>) {
    super(model);
    Object.assign(this, model);
    if (this.staticLookupEntries) {
      let tempValue = "";
      try {
        tempValue = JSON.stringify(JSON.parse(this.staticLookupEntries), null, 2);
      } catch {
        tempValue = this.staticLookupEntries;
      }
      this.staticLookupEntries = tempValue;
    }
  }
}
