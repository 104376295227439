
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Options, Vue } from "vue-class-component";
library.add(faTimes, faCaretDown);

@Options({
  props: {},
})
export default class Selector extends Vue {
  public displayValue = "";

  public options: any[] = [
    {
      label: "China",
      value: "CN",
    },
    {
      label: "Hong Kong",
      value: "HK",
    },
    {
      label: "Germany",
      value: "DE",
    },
  ];

  public filteredOptions: any[] = [];

  public selectedOption: any = {};

  public filterOptions(): void {
    const regex = new RegExp(this.displayValue, "gi");
    this.filteredOptions = this.options.filter((x) => {
      return x.label.match(regex);
    });
  }

  public setSelectedOption(option: any): void {
    this.selectedOption = option;
  }

  public removeSelectedOption(): void {
    this.selectedOption = {};
  }
}
