import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/users/Login.vue";
import Reset from "@/views/users/Reset.vue";
import Verify from "@/views/users/Verify.vue";
import Onboarding from "@/views/users/Onboarding.vue";
import Demo from "@/views/Demo.vue";
import DemoMulti from "@/views/DemoMulti.vue";
import GetContent from "@/views/GetContent.vue";
import { store } from "@/store";
import { hideChatWoot, showChatWoot } from "@/services/ChatWootService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/pricing",
    name: "landing-pricing",
    component: () => import(/* webpackChunkName: "landing" */ "../views/landing/Pricing.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/reset",
    name: "reset",
    component: Reset,
    meta: {
      requiresAuth: false,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/reset/:code",
    name: "reset.code",
    component: Reset,
    meta: {
      requiresAuth: false,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/verify/:code",
    name: "verify",
    component: Verify,
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: Onboarding,
    meta: {
      requiresAuth: false,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/demo",
    name: "demo",
    component: Demo,
    meta: {
      requiresAuth: true,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/demo-multi",
    name: "demo-multi",
    component: DemoMulti,
    meta: {
      requiresAuth: true,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/get-content/:contentId?",
    name: "get-content",
    component: GetContent,
    meta: {
      requiresAuth: true,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/",
    name: "home",
    redirect: {
      name: "products",
    },
    component: Home,
    meta: {
      requiresAuth: true,
      maintainBreadCrumbsX: false,
    },
    children: [
      // User details
      {
        path: "preferences",
        name: "preferences",
        meta: {
          requiresAuth: true,
          maintainBreadCrumbsX: false,
          disableLeftSidebar: true,
        },
        component: () => import(/* webpackChunkName: "user" */ "../views/users/Preferences.vue"),
      },
      {
        path: "organization/:orgId",
        name: "organization",
        meta: {
          requiresAuth: true,
          maintainBreadCrumbsX: false,
          disableLeftSidebar: true,
        },
        component: () => import(/* webpackChunkName: "env" */ "../views/organizations/Organization.vue"),
        children: [
          {
            path: "members",
            name: "org-members",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
              disableLeftSidebar: true,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/Members.vue"),
          },
          {
            path: "api-usage",
            name: "org-api-usage",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
              disableLeftSidebar: true,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/APIUsage.vue"),
          },
          {
            path: "assets-usage",
            name: "org-assets-usage",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
              disableLeftSidebar: true,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/AssetsUsage.vue"),
          },
          {
            path: "logs",
            name: "org-logs",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
              disableLeftSidebar: true,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/logs/OrganizationLogs.vue"),
          },
          {
            path: "billing",
            name: "org-billing",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
              disableLeftSidebar: true,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/billing/Billing.vue"),
          },
          {
            path: "billing/success",
            name: "org-billing-success",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
              disableLeftSidebar: true,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/billing/Success.vue"),
          },
        ],
      },
      // Products
      {
        path: "products",
        name: "products",
        meta: {
          requiresAuth: true,
          maintainBreadCrumbsX: false,
          disableLeftSidebar: true,
        },
        component: () => import(/* webpackChunkName: "products" */ "../views/products/Products.vue"),
      },
      {
        path: "products/:productId",
        name: "product",
        meta: {
          requiresAuth: true,
          maintainBreadCrumbsX: false,
        },
        // This is a hack to ensure that the productId is loaded before the component is loaded.
        // TODO: Rewrite all child pages to watch the presence of the product in the store and after that make the API calls
        beforeEnter: async (to, from, next) => {
          await store.commit("productSet", { contentId: to.params.productId });
          next();
        },
        component: () => import(/* webpackChunkName: "products" */ "../views/products/Product.vue"),
        children: [
          {
            path: "permissions",
            name: "permissions",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/products/Permissions.vue"),
          },
          {
            path: "logs",
            name: "product-logs",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/logs/ProductLogs.vue"),
          },
          {
            path: "assets",
            name: "assets",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/products/JoyAssets.vue"),
          },
          {
            path: "sync-environments",
            name: "sync-environments",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/products/SyncEnvironments.vue"),
          },
          {
            path: "schemas",
            name: "schemas",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env;" */ "../views/products/SchemasManager.vue"),
          },
          {
            path: "webhooks",
            name: "webhooks",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env;" */ "../views/products/Webhooks.vue"),
          },
          {
            path: "webhooks/create",
            name: "webhooks.create",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env;" */ "../views/products/Webhook.vue"),
          },
          {
            path: "webhooks/:hid",
            name: "webhooks.edit",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env;" */ "../views/products/Webhook.vue"),
          },
          {
            path: "settings",
            name: "prod-settings",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env;" */ "../views/products/Settings.vue"),
          },
          {
            path: "merge-configs",
            name: "merge-configs",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "merge-configs;" */ "../views/products/MergeConfigs.vue"),
          },
          {
            path: "env/:envId",
            name: "environment",
            redirect: {
              name: "folders",
            },
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            // This is a hack to ensure that the environment id is loaded before the component is loaded.
            // TODO: Rewrite all child pages to watch the presence of the product in the store and after that make the API calls
            beforeEnter: async (to, from, next) => {
              await store.commit("envSet", { contentId: to.params.envId });
              next();
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/env/Environment.vue"),
            children: [
              // API Keys
              {
                path: "logs",
                name: "envlogs",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: false,
                },
                component: () => import(/* webpackChunkName: "env" */ "../views/logs/EnvLogs.vue"),
              },
              // API Keys
              {
                path: "apikeys",
                name: "apikeys",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: false,
                },
                component: () => import(/* webpackChunkName: "env" */ "../views/env/Apikeys.vue"),
              },
              {
                path: "settings",
                name: "envsettings",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: false,
                },
                component: () => import(/* webpackChunkName: "env" */ "../views/env/EnvironmentSettings.vue"),
              },
              {
                path: "statistics",
                name: "envstats",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: false,
                },
                component: () => import(/* webpackChunkName: "env" */ "@/views/env/EnvironmentStatistics.vue"),
              },
              // Version-Stable Delivery
              {
                path: "version-stable-delivery",
                name: "version-stable",
                component: () => import(/* webpackChunkName: "env" */ "../views/env/VersionStable.vue"),
                children: [
                  {
                    path: "",
                    name: "version-stable.index",
                    meta: {
                      requiresAuth: true,
                      maintainBreadCrumbsX: false,
                    },
                    component: () =>
                      import(/* webpackChunkName: "env" */ "../views/env/versionStable/VersionStableIndex.vue"),
                  },
                  {
                    path: ":versionStableContentId",
                    name: "version-stable.edit",
                    meta: {
                      requiresAuth: true,
                      maintainBreadCrumbsX: false,
                    },
                    component: () =>
                      import(/* webpackChunkName: "env" */ "../views/env/versionStable/VersionStableEdit.vue"),
                  },
                ],
              },
              // Configs
              {
                path: "folders",
                name: "folders",
                meta: {
                  requiresAuth: true,
                  showLoader: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/Folders.vue"),
              },
              {
                path: "folders/:folders+",
                name: "folders.deep",
                meta: {
                  requiresAuth: true,
                  showLoader: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/Folders.vue"),
              },
              {
                path: "configs/:configId",
                name: "config",
                meta: {
                  requiresAuth: true,
                  showLoader: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/ConfigVersions.vue"),
              },
              {
                path: "configs/create",
                name: "configs.create",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/ConfigEdit.vue"),
              },
              {
                path: "configs/:configId/create",
                name: "configs.edit",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/ConfigEdit.vue"),
              },
              // TODO: allow direct linking to a particular version.
              // {
              //   path: "configs/:configId/:version",
              //   name: "configs.version",
              //   meta: {
              //     requiresAuth: true,
              //     maintainBreadCrumbsX: true,
              //   },
              //   component: () => import(/* webpackChunkName: "configs" */ "../views/configs/ConfigVersions.vue"),
              // },
              {
                path: "configs/:configId/controlpanel",
                name: "controlpanel",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/ControlPanel.vue"),
              },
              {
                path: "configs/:configId/controlpanel/edit",
                name: "controlpanel.edit",
                meta: {
                  requiresAuth: true,
                  maintainBreadCrumbsX: true,
                },
                component: () => import(/* webpackChunkName: "configs" */ "../views/configs/ControlPanelEdit.vue"),
              },
            ],
          },
          {
            path: "diff/:originalEnvContentId/:originalConfigContentId/:originalConfigVersion/:modifiedEnvContentId/:modifiedConfigContentId/:modifiedConfigVersion",
            name: "diff-page",
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "diff-viewer" */ "../views/diff/DiffPage.vue"),
          },
          {
            path: "feature-groups",
            name: "feature-groups",
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "feature-groups" */ "../views/products/FeatureGroups.vue"),
            children: [
              {
                path: "",
                name: "feature-groups-index",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "feature-groups-index" */ "../views/products/featureGroups/FeatureGroupsIndex.vue"
                  ),
              },
              {
                path: ":featureGroupHid",
                name: "feature-group-edit",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "feature-group-edit" */ "../views/products/featureGroups/FeatureGroupEdit.vue"
                  ),
              },
            ],
          },
          {
            path: "push-requests",
            name: "push-requests",
            meta: {
              requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "push-requests" */ "../views/products/PushRequests.vue"),
            children: [
              {
                path: "",
                name: "push-requests-index",
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import(/* webpackChunkName: "push-requests" */ "../views/products/pushRequests/PushRequestIndex.vue"),
              },
              {
                path: ":pushRequestHid",
                name: "push-request-view",
                meta: {
                  requiresAuth: true,
                },
                redirect: {
                  name: "diffs",
                },
                component: () =>
                  import(/* webpackChunkName: "push-request" */ "../views/products/pushRequests/PushRequestView.vue"),
                children: [
                  {
                    path: "diffs",
                    name: "diffs",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "push-requests" */ "../views/products/pushRequests/PushRequestDiffs.vue"
                      ),
                  },
                  {
                    path: "activity",
                    name: "activity",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "push-requests" */ "../views/products/pushRequests/PushRequestActivity.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (store.getters.isAwaitingToken) {
    await store.dispatch("tokenFromStorage");
  }

  if (!to.meta.maintainBreadCrumbsX) {
    store.commit("breadCrumbsXClear"); // if we are not going to one of the views that we should maintain the breadcrumb, then always clear it.
  }

  if (to.meta.requiresAuth) {
    hideChatWoot();
    if (!store.getters.isAuthenticated) {
      const currentLocation = encodeURI(window.location.pathname);
      store.commit("setLoading", false);
      next(`/login?continue=${currentLocation}`);
    } else {
      if (to.meta.showLoader && !store.getters.appIsLoaded) {
        store.commit("setLoading", true); // Show the long loader on bigger pages that will take longer to load on slower connections.
      }
      next();
    }
  } else {
    showChatWoot();
    next();
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach(async (to, from) => {
  if (to.meta.showLoader) {
    store.commit("setLoading", false);
  }

  // Don't overwrite the title if we are going to a custom title page.
  const customTitles = ["folders.deep", "folders", "configs.edit", "configs.create", "config"];
  if (to.name && !customTitles.includes(to.name.toString())) {
    document.title = "Joystick Dynamic Config Platform";
  }
});

export default router;
