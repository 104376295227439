export const getSchemaValues = (valuesJson: JSON, pattern: RegExp): JSON => {
  return getMatchingNodesInfo(valuesJson, "", JSON.parse("{}"), pattern);
};

// This takes the input and returns a 'flat' object with the keys matching the specified pattern anywhere in the input.
// Additionally it will provide a "location" and "value" of the node that matched the pattern.
const getMatchingNodesInfo = (node: JSON, path: string, result: JSON, pattern: RegExp): JSON => {
  const regexInner = new RegExp(pattern);
  for (const attr in node) {
    if (regexInner.test(attr)) {
      // If it is a CP key, pull out the actual CP-xyz part of it. #CP-Key:key# => CP-Key
      const key = attr.split(":")[0].replace("#", "");
      result[key] = { location: path + (path == "" ? "" : ".") + attr, value: node[attr] };
    } else {
      if (typeof node[attr] === "object") {
        result = getMatchingNodesInfo(node[attr], path + (path == "" ? "" : ".") + attr, result, pattern);
      }
    }
  }
  return result;
};

export const generateBasicControlPanelSchemaHelper = (contentConfig: JSON, pattern: RegExp): JSON => {
  // Let's get all of the nodes that have Control Panel declaration first, in a flatten format.
  const matchedNodes = getMatchingNodesInfo(contentConfig, "", JSON.parse("{}"), pattern);
  return controlPanelSchemaGenerator(matchedNodes);
};

const controlPanelSchemaGenerator = (matchedNodes: JSON): JSON => {
  // eslint-disable-next-line
  let result = JSON.parse("{}");
  for (const [key, nodeDef] of Object.entries(matchedNodes)) {
    const generatedSchema = buildControlPanelSchemaFromItemIterator(nodeDef.value, JSON.parse("{}"));
    result[key] = {
      cp_block_options: {
        title: "",
        description: "",
      },
      schema: generatedSchema,
    };
    result[key].schema.title = key; // Set a default for the first level items.
  }
  return result;
};

// Iterates over the item presented to build a control panel schema.
const buildControlPanelSchemaFromItemIterator = (itemToProcess: JSON, result: JSON): JSON => {
  let nodeType = `${typeof itemToProcess}`;
  if (nodeType === "object" && itemToProcess.constructor === Array) {
    nodeType = "array";
  }

  result["type"] = nodeType;
  result["title"] = "";
  result["description"] = "";

  switch (nodeType) {
    case "object":
      result["properties"] = JSON.parse("{}");
      for (const [key, item] of Object.entries(itemToProcess)) {
        result["properties"][key] = buildControlPanelSchemaFromItemIterator(item, JSON.parse("{}"));
        result["properties"][key]["title"] = key; // Set a default title based on the key of the JSON.
      }
      break;

    case "array":
      result["items"] = JSON.parse("{}");
      // If the item is an array, us the first item in that array as the prototype to build the schema on.
      result["cp_widget_options"] = { array_item_noun: "" };
      if (Object.entries(itemToProcess).length >= 1) {
        result["items"] = buildControlPanelSchemaFromItemIterator(itemToProcess[0], JSON.parse("{}"));
      }
      break;

    case "boolean":
      result["cp_widget_options"] = { label_true: "True", label_false: "False", disabled: false };
      break;

    case "string":
      result["enum"] = [];
      result["cp_widget_options"] = { input_type: "", placeholder: "", disabled: false };
      break;

    case "number":
      result["enum"] = [];
      result["cp_widget_options"] = { input_type: "", placeholder: "", disabled: false };
      break;

    default:
      break;
  }

  return result;
};
