import { Attachment } from "./Attachment";
import { BaseModel } from "./BaseModel";

export class ContentSchema extends BaseModel {
  public id = 0;
  public data = "";
  public description = "";
  public dataType = 1;
  public schemaFolderId = 0;

  public attachments: Attachment[] = [];

  public constructor(model: Partial<ContentSchema>) {
    super(model);
    Object.assign(this, model);
  }
}
