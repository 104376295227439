import { DataRes } from "@/models/DataRes";
import { request } from "./BaseService";
import { PushSyncEnvResponse } from "./SyncEnvironmentService";
import { ListPageModel } from "@/models/ListPageModel";
import { PushRequest } from "@/models/PushRequest";
import { PushRequestModel } from "@/models/PushRequestModel";
import { PushRequestActivityLog } from "@/models/PushRequestActivityLog";

const endpoint = "/product";

export const createPushRequest = async ({
  productContentId,
  contents,
  sourceContentId,
  targetContentId,
  pushRequestName,
}): Promise<DataRes<PushSyncEnvResponse>> => {
  return request<PushSyncEnvResponse>(
    `${endpoint}/${productContentId}/push-request/${sourceContentId}/${targetContentId}`,
    {
      method: "POST",
      body: JSON.stringify({ name: pushRequestName, configs: contents }),
    }
  );
};

export const getPage = async (
  productContentId: string,
  page: number,
  pageSize: number
): Promise<DataRes<ListPageModel<PushRequest>>> => {
  const data = await request<ListPageModel<PushRequest>>(
    `${endpoint}/${productContentId}/push-request?page=${page}&pageSize=${pageSize}`,
    {
      method: "GET",
    }
  );
  return data;
};

export const getModel = async (productContentId: string, hid: string): Promise<DataRes<PushRequestModel>> => {
  const data = await request<PushRequestModel>(`${endpoint}/${productContentId}/push-request/${hid}`, {
    method: "GET",
  });
  return data;
};

export const cancel = async (productContentId: string, hid: string): Promise<DataRes<PushRequestModel>> => {
  const data = await request<PushRequestModel>(`${endpoint}/${productContentId}/push-request/${hid}/cancel`, {
    method: "PUT",
  });
  return data;
};

export const confirm = async (productContentId: string, hid: string): Promise<DataRes<PushRequestModel>> => {
  const data = await request<PushRequestModel>(`${endpoint}/${productContentId}/push-request/${hid}/confirm`, {
    method: "PUT",
  });
  return data;
};

export const getLogs = async (productContentId: string, hid: string): Promise<DataRes<PushRequestActivityLog[]>> => {
  const data = await request<PushRequestActivityLog[]>(`${endpoint}/${productContentId}/push-request/${hid}/logs`, {
    method: "GET",
  });
  data.data = data.data.map((i) => new PushRequestActivityLog(i));
  return data;
};

export const approve = async (
  productContentId: string,
  hid: string,
  type: string
): Promise<DataRes<PushRequestModel>> => {
  const data = await request<PushRequestModel>(`${endpoint}/${productContentId}/push-request/${hid}/approve/${type}`, {
    method: "POST",
  });
  return data;
};
