import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["copy", { inline: _ctx.inline }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClip()))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("span", {
      class: _normalizeClass(["ico", { 'always-visible': _ctx.iconAlwaysVisible }])
    }, [
      _createVNode(_component_font_awesome_icon, { icon: "copy" })
    ], 2)
  ], 2))
}